<template>
  <div class="privacy_policy" style="text-align: start">
    <h1 style="text-align: center">Privacy Policy of Hearto.AI</h1>
    <p><b>Privacy Policy</b></p>
    <p>
      <i>Last Updated: August 6, 2024</i>
    </p>
    <h2>1. Introduction</h2>
    <p>
      This privacy policy applies to ENCOMPASS GLOBAL CO., LTD. (hereby referred to as "service provider", "we", "us"
      and/or "our") and the Hearto.AI application (hereby referred to as "the Application", "the Service") created by
      us. We value your privacy rights. This privacy policy explains how we collect, use, share, and protect your
      personal information. Using our application indicates your agreement with this privacy policy. If you do not agree
      with these terms, please refrain from using this application.
    </p>

    <h2>2.Information We Collect</h2>

    During your use of our services, we may collect the following types of information:

    <h3>Personal Information:</h3>
    <p>Information provided by users during registration or use of the application, including username, email addresses,
      gender, age, profile picture, etc. You are not required to provide this information, but some features may not be
      available if you choose not to.</p>
    <h3>Communication Data:</h3>
    <p>Your conversations, chat logs, and communication content within the application.</p>
    <h3>Device Information:</h3>
    <p>Including IP address, operating system, device type, device identifiers, etc.</p>
    <h3>Usage Data: </h3>
    <p>Information about how users access and use the application.</p>

    <h2>3. How We Use Your Information</h2>
    We may use your information for the following purposes:
    <h3>Providing and Improving Services: </h3>
    <p>
      Using your personal information to provide, maintain, and improve our services.
    </p>
    <h3>Personalized Experience: </h3>
    <p>
      Personalizing recommendations and generating AI conversation content based on your communication data
    </p>

    <h3>Security and Compliance: </h3>
    <p>
      Using your information to maintain the security of the application and comply with legal requirements.
    </p>
    <h2>4. Sharing Your Information</h2>
    We may share your information with the following types of third parties:
    <h3>Service Providers: </h3>
    <p>
      Third parties that help us provide services (such as data analysis, hosting services).
    </p>

    <h3>Business Partners: </h3>
    <p>Advertising and marketing partners that work with us to help with targeted advertising.</p>

    <h3>Legal and Security Purposes:</h3>
    <p>Sharing with law enforcement agencies or government bodies when required by law or to protect our rights and security.</p>


    <h3>Business Transfers:</h3>
    <p>Disclosing certain information during the negotiation or completion of any merger, sale of company assets, financing, or acquisition of all or part of our business to another company.</p>

    <p>We assure that any information shared with third parties will be appropriately protected, ensuring its security and confidentiality.</p>

    <h2>5. Your Rights</h2>
    <p>
      You have the right to access, correct, or delete your personal data and have the right to restrict or object to our processing of your data. Additionally, you may withdraw consent, but this will not affect the legality of any processing we have carried out based on consent prior to its withdrawal. If you want to make a request, please use customer service on our website or application. In addition, you can also contact us as described in the "Contact Us" section below. We may ask you to provide specific information to help us confirm your identity.
    </p>

    Account deletion URL provided :  https://xxx.com/del_acc

    <h2>6. Data Security</h2>
    <p>
      We employ reasonable technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. This includes measures such as encryption and access controls. However, we do not guarantee 100% data security.
    </p>
    <h2>7. Data Retention </h2>
    <p>
      We will retain your personal information based on the purpose of collection and legal requirements. For example, we will retain your chat logs for as long as necessary to provide the service. Once the data retention period ends, we will securely delete or anonymize the data.
    </p>
    <h2>8. International Data Transfers </h2>
    <p>
      Our services are global, and your information may be stored and processed in the United States and other countries. The privacy protection laws in these countries may differ from the laws of the country where you reside and/or are a citizen. By using our services or providing us with any information, you consent to the collection, processing, maintenance, and transfer of such information to and/or from the United States and other applicable countries.
    </p>
    <h2>9. Children's Privacy</h2>
    <p>
      Our services are not designed for individuals under the age of 18. Our terms of service prohibit individuals under the age of 18 from using our services. If we discover that an individual under the age of 18 has provided us with personal information, we will close the account and delete the personal information.
    </p>
    <h2>10. Changes to This Privacy Policy</h2>
    <p>
      We may update this privacy policy from time to time. When we make significant changes, we will notify you within the application or through other appropriate means. Continued use of the application indicates your acceptance of the updated privacy policy.
    </p>
    <p>
      When you provide personal data to us via the Platform, it is stored on a
      cloud server located in Dubai.
    </p>
    <h2>11. Contact Information</h2>
    <p>
      If you have any questions or requests regarding this privacy policy, please contact us at:
Email: c21931258@gmail.com
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy Policy",
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
  },
};
</script>

<style scoped>
h2 {
  text-align: start;
}

p {
  text-align: start;
}
</style>