<template>
  <div class="container">

    <div class="headerBlock">
      <img class="header" src="../assets/2.png" alt="">
      <img src="../assets/logo.png" alt="" class="logo">
      <div class="topBtns flex ac jc">
      </div>
      <div class="download">
        <a href="https://play.google.com/store/apps/details?id=com.julan.hearto" target="_blank">
          <img src="../assets/btn_google@2x.png" alt="Open in Google Play" class="btn_android">
        </a>
        <!-- <img src="images/btn_apple@2x.png" alt="" class="btn_ios"> -->
      </div>
    </div>
    <!-- <img class="header" src="images/2.png" alt=""> -->

    <div class="headerBlock">
      <!-- <img class="header" src="images/3.png" alt=""> -->
      <div class="word">
        Copyright © 2024 APP Hearto.ai Copyright <br><br>
        ENCOMPASS GLOBAL CO., LTD. Belong <br>
        Contact email：c21931258@gmail.com
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>

.download {
  padding-top: 320px; /* 或者尝试 margin-top */

}

.btn_android {
  width: 100%; /* Ensure the button scales well */
  max-width: 300px; /* Optionally set a max-width for the button */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>