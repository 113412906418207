<template>
  <div class="service_terms">
    <h1 style="text-align: center">Terms of Service</h1>
    <p>
      If you are a US resident, this Terms of Service shall apply.<br />
      If you are a user having your usual residence in the EU, this Terms of
      Service shall apply.<br />
      If your residence is in another country, and not the US or EU, this Terms
      of Service shall apply.<br />
    </p>
    <p>
      <b>Terms of Service</b><br />
      (If you are a US resident)<br />
      <i>Last updated: JUN 2021</i>
    </p>
    <h2>1. Your Relationship With Us</h2>
    <p>
      Welcome to Wyak (the “Platform”), which is provided by WeNext Limited in
      the Hong Kong(collectively such entities will be referred to as “Wyak”,
      “we” or “us”).<br />
      You are reading the Terms of Service (the “Terms”), which govern the
      relationship and serve as an agreement between you and us and set forth
      the terms and conditions by which you may access and use the Platform and
      our related websites, services, applications, products and content
      (collectively, the “Services”). Access to certain Services or features of
      the Services (such as, by way of example and not limitation, the ability
      to submit or share User Content (defined below)) may be subject to age
      restrictions and not available to all users of the Services. Our Services
      are provided for private, non-commercial use. For purposes of these Terms,
      “you” and “your” means you as the user of the Services.<br />
      The Terms form a legally binding agreement between you and us. Please take
      the time to read them carefully. If you are under age 17, you may only use
      the Services with the consent of your parent or legal guardian. Please be
      sure your parent or legal guardian has reviewed and discussed these Terms
      with you.<br />
      ARBITRATION NOTICE FOR USERS IN THE UNITED STATES: THESE TERMS CONTAIN AN
      ARBITRATION CLAUSE AND A WAIVER OF RIGHTS TO BRING A CLASS ACTION AGAINST
      US. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION
      CLAUSE, YOU AND Wyak AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY
      MANDATORY BINDING ARBITRATION, AND YOU AND Wyak WAIVE ANY RIGHT TO
      PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
    </p>
    <h2>2. Accepting the Terms</h2>
    <p>
      By accessing or using our Services, you confirm that you can form a
      binding contract with Wyak, that you accept these Terms and that you agree
      to comply with them. Your access to and use of our Services is also
      subject to our Privacy Policy and Community Guidelines, the terms of which
      can be found directly on the Platform, or where the Platform is made
      available for download, on your mobile device’s applicable app store, and
      are incorporated herein by reference. By using the Services, you consent
      to the terms of the Terms of Service.<br />
      If you are accessing or using the Services on behalf of a business or
      entity, then (a) “you” and “your” includes you and that business or
      entity, (b) you represent and warrant that you are an authorized
      representative of the business or entity with the authority to bind the
      entity to these Terms, and that you agree to these Terms on the entity’s
      behalf, and (c) your business or entity is legally and financially
      responsible for your access or use of the Services as well as for the
      access or use of your account by others affiliated with your entity,
      including any employees, agents or contractors.<br />
      You can accept the Terms by accessing or using our Services. You
      understand and agree that we will treat your access or use of the Services
      as acceptance of the Terms from that point onwards.<br />
      You should print off or save a local copy of the Terms for your records.
    </p>
    <h2>3. Changes to the Terms</h2>
    <p>
      We amend these Terms from time to time, for instance when we update the
      functionality of our Services, when we combine multiple apps or services
      operated by us or our affiliates into a single combined service or app, or
      when there are regulatory changes. We will use commercially reasonable
      efforts to generally notify all users of any material changes to these
      Terms, such as through a notice on our Platform, however, you should look
      at the Terms regularly to check for such changes. We will also update the
      “Last Updated” date at the top of these Terms, which reflect the effective
      date of such Terms. Your continued access or use of the Services after the
      date of the new Terms constitutes your acceptance of the new Terms. If you
      do not agree to the new Terms, you must stop accessing or using the
      Services.
    </p>
    <h2>4. Your Account with Us</h2>
    <p>
      To access or use some of our Services, you must create an account with us.
      When you create this account, you must provide accurate and up-to-date
      information. It is important that you maintain and promptly update your
      details and any other information you provide to us, to keep such
      information current and complete.<br />
      It is important that you keep your account password confidential and that
      you do not disclose it to any third party. If you know or suspect that any
      third party knows your password or has accessed your account, you can
      reset your password by receiving password recovery SMS via your registered
      phone number and promptly notify us at account wenext.wyak@gmail.com<br />
      You agree that you are solely responsible (to us and to others) for the
      activity that occurs under your account.<br />
      We reserve the right to disable your user account at any time, including
      if you have failed to comply with any of the provisions of these Terms, or
      if activities occur on your account which, in our sole discretion, would
      or might cause damage to or impair the Services or infringe or violate any
      third party rights, or violate any applicable laws or regulations.<br />
      If you no longer want to use our Services again, and would like your
      account deleted, we can take care of this for you. Please contact us via
      Email Address: wenext.wyak@gmail.com<br />
      , and we will provide you with further assistance and guide you through
      the process. Once you choose to delete your account, you will not be able
      to reactivate your account or retrieve any of the content or information
      you have added.
    </p>
    <h2>5. Your Access to and Use of Our Services</h2>
    <p>
      Your access to and use of the Services is subject to these Terms and all
      applicable laws and regulations. You may not:<br />
      access or use the Services if you are not fully able and legally competent
      to agree to these Terms or are authorized to use the Services by your
      parent or legal guardian;<br />
      make unauthorised copies, modify, adapt, translate, reverse engineer,
      disassemble, decompile or create any derivative works of the Services or
      any content included therein, including any files, tables or documentation
      (or any portion thereof) or determine or attempt to determine any source
      code, algorithms, methods or techniques embodied by the Services or any
      derivative works thereof;<br />
      distribute, license, transfer, or sell, in whole or in part, any of the
      Services or any derivative works thereof;<br />
      market, rent or lease the Services for a fee or charge, or use the
      Services to advertise or perform any commercial solicitation;<br />
      use the Services, without our express written consent, for any commercial
      or unauthorized purpose, including communicating or facilitating any
      commercial advertisement or solicitation or spamming;<br />
      interfere with or attempt to interfere with the proper working of the
      Services, disrupt our website or any networks connected to the Services,
      or bypass any measures we may use to prevent or restrict access to the
      Services;<br />
      incorporate the Services or any portion thereof into any other program or
      product. In such case, we reserve the right to refuse service, terminate
      accounts or limit access to the Services in our sole discretion;use
      automated scripts to collect information from or otherwise interact with
      the Services;<br />
      impersonate any person or entity, or falsely state or otherwise
      misrepresent you or your affiliation with any person or entity, including
      giving the impression that any content you upload, post, transmit,
      distribute or otherwise make available emanates from the Services;<br />
      intimidate or harass another, or promote sexually explicit material,
      violence or discrimination based on race, sex, religion, nationality,
      disability, sexual orientation or age;<br />
      use or attempt to use another’s account, service or system without
      authorisation from Wyak, or create a false identity on the Services;use
      the Services in a manner that may create a conflict of interest or
      undermine the purposes of the Services, such as trading reviews with other
      users or writing or soliciting fake reviews;use the Services to upload,
      transmit, distribute, store or otherwise make available in any way: files
      that contain viruses, trojans, worms, logic bombs or other material that
      is malicious or technologically harmful; any unsolicited or unauthorised
      advertising, solicitations, promotional materials, “junk mail,” “spam,”
      “chain letters,” “pyramid schemes,” or any other prohibited form of
      solicitation; any private information of any third party, including
      addresses, phone numbers, email addresses, number and feature in the
      personal identity document (e.g., National Insurance numbers, passport
      numbers) or credit card numbers;any material which does or may infringe
      any copyright, trademark or other intellectual property or privacy rights
      of any other person;any material which is defamatory of any person,
      obscene, offensive, pornographic, hateful or inflammatory; any material
      that would constitute, encourage or provide instructions for a criminal
      offence, dangerous activities or self-harm; any material that is
      deliberately designed to provoke or antagonise people, especially trolling
      and bullying, or is intended to harass, harm, hurt, scare, distress,
      embarrass or upset people; any material that contains a threat of any
      kind, including threats of physical violence; any material that is racist
      or discriminatory, including discrimination on the basis of someone’s
      race, religion, age, gender, disability or sexuality; any answers,
      responses, comments, opinions, analysis or recommendations that you are
      not properly licensed or otherwise qualified to provide; or material that,
      in the sole judgment of Wyak, is objectionable or which restricts or
      inhibits any other person from using the Services, or which may expose
      Wyak, the Services or its users to any harm or liability of any type.In
      addition to the above, your access to and use of the Services must, at all
      times, be compliant with our Community Guidelines.<br />
      We reserve the right, at any time and without prior notice, to remove or
      disable access to content at our discretion for any reason or no reason.
      Some of the reasons we may remove or disable access to content may include
      finding the content objectionable, in violation of these Terms or our
      Community Guidelines, or otherwise harmful to the Services or our users.
      Our automated systems analyze your content to provide you personally
      relevant product features, such as customized search results, and content
      recommendation. This analysis occurs as the content is sent, received, and
      when it is stored.
    </p>
    <h2>6. Intellectual Property Rights</h2>
    <p>
      We respect intellectual property rights and ask you to do the same. As a
      condition of your access to and use of the Services, you agree to the
      terms of the Copyright Policy.
    </p>
    <h2>7. Content</h2>
    <p>Email Address: wenext.wyak@gmail.com</p>
    <p><b>A. Wyak Content</b></p>
    <p>
      As between you and Wyak, all content, software, images, text, graphics,
      illustrations, logos, patents, trademarks, service marks, copyrights,
      photographs, audio, music on and “look and feel” of the Services, and all
      intellectual property rights related thereto (the “Wyak Content”), are
      either owned or licensed by Wyak, it being understood that you or your
      licensors will own any User Content (as defined below) you upload or
      transmit through the Services. Use of the Wyak Content or materials on the
      Services for any purpose not expressly permitted by these Terms is
      strictly prohibited. Such content may not be downloaded, copied,
      reproduced, distributed, transmitted, broadcast, displayed, sold, licensed
      or otherwise exploited for any purpose whatsoever without our or, where
      applicable, our licensors’ prior written consent. We and our licensors
      reserve all rights not expressly granted in and to their content.<br />
      You acknowledge and agree that we may generate revenues, increase goodwill
      or otherwise increase our value from your use of the Services, including,
      by way of example and not limitation, through the sale of Virtual Gifts
      and related Services, and except as specifically permitted by us in these
      Terms or in another agreement you enter into with us, you will have no
      right to share in any such revenue, goodwill or value whatsoever. You
      further acknowledge that, except as specifically permitted by us in these
      Terms or in another agreement you enter into with us, you (i) have no
      right to receive any income or other consideration from any User Content
      or your use of any musical works, sound recordings or audiovisual clips
      made available to you on or through the Services, including in any User
      Content created by you, and (ii) are prohibited from exercising any rights
      to monetize or obtain consideration from any User Content within the
      Services or on any third party service (e.g. , you cannot claim User
      Content that has been uploaded to a social media platform such as YouTube
      for monetization).<br />
      Subject to the terms and conditions of the Terms, you are hereby granted a
      non-exclusive, limited, non-transferable, non-sublicensable, revocable,
      worldwide license to access and use the Services, including to download
      the Platform on a permitted device, and to access the Wyak Content solely
      for your personal, non-commercial use through your use of the Services and
      solely in compliance with these Terms. Wyak reserves all rights not
      expressly granted herein in the Services and the Wyak Content. You
      acknowledge and agree that Wyak may terminate this license at any time for
      any reason or no reason.<br />
      NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE MUSICAL
      WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE FROM OR THROUGH THE
      SERVICE.<br />
      You acknowledge and agree that when you view content provided by others on
      the Services, you are doing so at your own risk. The content on our
      Services is provided for general information only. It is not intended to
      amount to advice on which you should rely. You must obtain professional or
      specialist advice before taking, or refraining from, any action on the
      basis of the content on our Services.<br />
      We make no representations, warranties or guarantees, whether express or
      implied, that any Wyak Content (including User Content) is accurate,
      complete or up to date. Where our Services contain links to other sites
      and resources provided by third parties, these links are provided for your
      information only. We have no control over the contents of those sites or
      resources. Such links should not be interpreted as approval by us of those
      linked websites or information you may obtain from them. You acknowledge
      that we have no obligation to pre-screen, monitor, review, or edit any
      content posted by you and other users on the Services (including User
      Content).
    </p>
    <p><b>B. User-Generated Content</b></p>
    <p>
      Users of the Services may be permitted to upload, post or transmit (such
      as via a stream) or otherwise make available content through the Services
      including, without limitation, any text, photographs, sound recordings and
      the musical works embodied therein, including sound recordings from your
      personal music library and ambient noise (“User Content”). Users of the
      Services may also extract all or any portion of User Content created by
      another user to produce additional User Content, including collaborative
      User Content with other users, that combine and intersperse User Content
      generated by more than one user. Users of the Services may also overlay
      music, graphics, stickers, Virtual Items and other elements provided by
      Wyak (“Wyak Elements”) onto this User Content and transmit this User
      Content through the Services. The information and materials in the User
      Content, including User Content that includes Wyak Elements, have not been
      verified or approved by us. The views expressed by other users on the
      Services (including through use of the virtual gifts) do not represent our
      views or values.<br />
      Whenever you access or use a feature that allows you to upload or transmit
      User Content through the Services (including via certain third party
      social media platforms such as Instagram, Facebook, YouTube, Twitter), or
      to make contact with other users of the Services, you must comply with the
      standards set out at “Your Access to and Use of Our Services” above. You
      may also choose to upload or transmit your User Content, including User
      Content that includes Wyak Elements, on sites or platforms hosted by third
      parties. If you decide to do this, you must comply with their content
      guidelines as well as with the standards set out at “Your Access to and
      Use of Our Services” above. As noted above, these features may not be
      available to all users of the Services, and we have no liability to you
      for limiting your right to certain features of the Services.<br />
      You warrant that any such contribution does comply with those standards,
      and you will be liable to us and indemnify us for any breach of that
      warranty. This means you will be responsible for any loss or damage we
      suffer as a result of your breach of warranty.<br />
      Any User Content will be considered non-confidential and non-proprietary.
      You must not post any User Content on or through the Services or transmit
      to us any User Content that you consider to be confidential or
      proprietary. When you submit User Content through the Services, you agree
      and represent that you own that User Content, or you have received all
      necessary permissions, clearances from, or are authorised by, the owner of
      any part of the content to submit it to the Services, to transmit it from
      the Services to other third party platforms, and/or adopt any third party
      content.<br />
      If you only own the rights in and to a sound recording, but not to the
      underlying musical works embodied in such sound recordings, then you must
      not post such sound recordings to the Services unless you have all
      permissions, clearances from, or are authorised by, the owner of any part
      of the content to submit it to the Services.<br />
      You or the owner of your User Content still own the copyright in User
      Content sent to us, but by submitting User Content via the Services, you
      hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
      fully transferable, perpetual worldwide licence to use, modify, adapt,
      reproduce, make derivative works of, publish and/or transmit, and/or
      distribute and to authorise other users of the Services and other
      third-parties to view, access, use, download, modify, adapt, reproduce,
      make derivative works of, publish and/or transmit your User Content in any
      format and on any platform, either now known or hereinafter invented.<br />
      You further grant us a royalty-free license to use your user name, image,
      voice, and likeness to identify you as the source of any of your User
      Content; provided, however, that your ability to provide an image, voice,
      and likeness may be subject to limitations due to age restrictions.<br />
      For the avoidance of doubt, the rights granted in the preceding paragraphs
      of this Section include, but are not limited to, the right to reproduce
      sound recordings (and make mechanical reproductions of the musical works
      embodied in such sound recordings), and publicly perform and communicate
      to the public sound recordings (and the musical works embodied therein),
      all on a royalty-free basis. This means that you are granting us the right
      to use your User Content without the obligation to pay royalties to any
      third party, including, but not limited to, a sound recording copyright
      owner (e.g., a record label), a musical work copyright owner (e.g., a
      music publisher), a performing rights organization (e.g., ASCAP, BMI,
      SESAC, etc.) (a “PRO”), a sound recording PRO (e.g., SoundExchange), any
      unions or guilds, and engineers, producers or other royalty participants
      involved in the creation of User Content.<br />
      Specific Rules for Musical Works and for Recording Artists. If you are a
      composer or author of a musical work and are affiliated with a PRO, then
      you must notify your PRO of the royalty-free license you grant through
      these Terms in your User Content to us. You are solely responsible for
      ensuring your compliance with the relevant PRO’s reporting obligations. If
      you have assigned your rights to a music publisher, then you must obtain
      the consent of such music publisher to grant the royalty-free license(s)
      set forth in these Terms in your User Content or have such music publisher
      enter into these Terms with us. Just because you authored a musical work
      (e.g., wrote a song) does not mean you have the right to grant us the
      licenses in these Terms. If you are a recording artist under contract with
      a record label, then you are solely responsible for ensuring that your use
      of the Services is in compliance with any contractual obligations you may
      have to your record label, including if you create any new recordings
      through the Services that may be claimed by your label
      Through-To-The-Audience Rights . All of the rights you grant in your User
      Content in these Terms are provided on a through-to-the-audience basis,
      meaning the owners or operators of third party services will not have any
      separate liability to you or any other third party for User Content posted
      or used on such third party service via the Services.<br />
      Waiver of Rights to User Content. By posting User Content to or through
      the Services, you waive any rights to prior inspection or approval of any
      marketing or promotional materials related to such User Content. You also
      waive any and all rights of privacy, publicity, or any other rights of a
      similar nature in connection with your User Content, or any portion
      thereof, unless privacy setting is applied when you post such content. To
      the extent any moral rights are not transferable or assignable, you hereby
      waive and agree never to assert any and all moral rights, or to support,
      maintain or permit any action based on any moral rights that you may have
      in or with respect to any User Content you Post to or through the
      Services.<br />
      We also have the right to disclose your identity to any third party who is
      claiming that any User Content posted or uploaded by you to our Services
      constitutes a violation of their intellectual property rights, or of their
      right to privacy, and such disclosure will be pursuant to a legally
      binding court order.<br />
      We, or authorised third parties, reserve the right to cut, crop, edit or
      refuse to publish, your content at our or their sole discretion. We have
      the right to remove, disallow, block or delete any posting you make on our
      Services if, in our opinion, your post does not comply with the content
      standards set out at “Your Access to and Use of Our Services”above. In
      addition, we have the right – but not the obligation – in our sole
      discretion to remove, disallow, block or delete any User Content (i) that
      we consider to violate these Terms, or (ii) in response to complaints from
      other users or third parties, with or without notice and without any
      liability to you. As a result, we recommend that you save copies of any
      User Content that you post to the Services on your personal device(s) in
      the event that you want to ensure that you have permanent access to copies
      of such User Content. We do not guarantee the accuracy, integrity,
      appropriateness or quality of any User Content, and under no circumstances
      will we be liable in any way for any User Content.<br />
      We accept no liability in respect of any content submitted by users and
      published by us or by authorised third parties.<br />
      If you wish to complain about information and materials uploaded by other
      users, please contact us in Wyak APP Feedback in Settings or email to
      Email Address: wenext.wyak@gmail.com Before we can respond to your
      request, you may be required to verify your identity or your account
      details.<br />
      Wyak takes reasonable measures to expeditiously remove from our Services
      any infringing material that we become aware of. It is Wyak’s policy, in
      appropriate circumstances and at its discretion, to disable or terminate
      the accounts of users of the Services who repeatedly infringe copyrights
      or intellectual property rights of others.<br />
      While our own staff is continually working to develop and evaluate our own
      product ideas and features, we pride ourselves on paying close attention
      to the interests, feedback, comments, and suggestions we receive from the
      user community. If you choose to contribute by sending us or our employees
      any ideas for products, services, features, modifications, enhancements,
      content, refinements, technologies, content offerings (such as audio,
      visual, games, or other types of content), promotions, strategies, or
      product/feature names, or any related documentation, artwork, computer
      code, diagrams, or other materials (collectively “Feedback”), then
      regardless of what your accompanying communication may say, the following
      terms will apply, so that future misunderstandings can be avoided.
      Accordingly, by sending Feedback to us, you agree that:<br />
      Wyak has no obligation to review, consider, or implement your Feedback, or
      to return to you all or part of any Feedback for any reason;<br />
      Feedback is provided on a non-confidential basis, and we are not under any
      obligation to keep any Feedback you send confidential or to refrain from
      using or disclosing it in any way; and<br />
      You irrevocably grant us perpetual and unlimited permission to reproduce,
      distribute, create derivative works of, modify, publicly perform
      (including on a through-to-the-audience basis), communicate to the public,
      make available, publicly display, and otherwise use and exploit the
      Feedback and derivatives thereof for any purpose and without restriction,
      free of charge and without attribution of any kind, including by making,
      using, selling, offering for sale, importing, and promoting commercial
      products and services that incorporate or embody Feedback, whether in
      whole or in part, and whether as provided or as modified.<br />
      Users of the Services may be permitted to upload, post or transmit (such
      as via a stream) or otherwise make available content through the Services
      including, without limitation, any text, photographs, sound recordings and
      the musical works embodied therein, including sound recordings from your
      personal music library and ambient noise (“User Content”). Users of the
      Services may also extract all or any portion of User Content created by
      another user to produce additional User Content, including collaborative
      User Content with other users, that combine and intersperse User Content
      generated by more than one user. Users of the Services may also overlay
      music, graphics, stickers, Virtual Items and other elements provided by
      Wyak (“Wyak Elements”) onto this User Content and transmit this User
      Content through the Services. The information and materials in the User
      Content, including User Content that includes Wyak Elements, have not been
      verified or approved by us. The views expressed by other users on the
      Services (including through use of the virtual gifts) do not represent our
      views or values.<br />
      Whenever you access or use a feature that allows you to upload or transmit
      User Content through the Services (including via certain third party
      social media platforms such as Instagram, Facebook, YouTube, Twitter), or
      to make contact with other users of the Services, you must comply with the
      standards set out at “Your Access to and Use of Our Services” above. You
      may also choose to upload or transmit your User Content, including User
      Content that includes Wyak Elements, on sites or platforms hosted by third
      parties. If you decide to do this, you must comply with their content
      guidelines as well as with the standards set out at “Your Access to and
      Use of Our Services” above. As noted above, these features may not be
      available to all users of the Services, and we have no liability to you
      for limiting your right to certain features of the Services.<br />
      You warrant that any such contribution does comply with those standards,
      and you will be liable to us and indemnify us for any breach of that
      warranty. This means you will be responsible for any loss or damage we
      suffer as a result of your breach of warranty.<br />
      Any User Content will be considered non-confidential and non-proprietary.
      You must not post any User Content on or through the Services or transmit
      to us any User Content that you consider to be confidential or
      proprietary. When you submit User Content through the Services, you agree
      and represent that you own that User Content, or you have received all
      necessary permissions, clearances from, or are authorised by, the owner of
      any part of the content to submit it to the Services, to transmit it from
      the Services to other third party platforms, and/or adopt any third party
      content.<br />
      If you only own the rights in and to a sound recording, but not to the
      underlying musical works embodied in such sound recordings, then you must
      not post such sound recordings to the Services unless you have all
      permissions, clearances from, or are authorised by, the owner of any part
      of the content to submit it to the Services.<br />
      You or the owner of your User Content still own the copyright in User
      Content sent to us, but by submitting User Content via the Services, you
      hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
      fully transferable, perpetual worldwide licence to use, modify, adapt,
      reproduce, make derivative works of, publish and/or transmit, and/or
      distribute and to authorise other users of the Services and other
      third-parties to view, access, use, download, modify, adapt, reproduce,
      make derivative works of, publish and/or transmit your User Content in any
      format and on any platform, either now known or hereinafter invented.<br />
      You further grant us a royalty-free license to use your user name, image,
      voice, and likeness to identify you as the source of any of your User
      Content; provided, however, that your ability to provide an image, voice,
      and likeness may be subject to limitations due to age restrictions.<br />
      For the avoidance of doubt, the rights granted in the preceding paragraphs
      of this Section include, but are not limited to, the right to reproduce
      sound recordings (and make mechanical reproductions of the musical works
      embodied in such sound recordings), and publicly perform and communicate
      to the public sound recordings (and the musical works embodied therein),
      all on a royalty-free basis. This means that you are granting us the right
      to use your User Content without the obligation to pay royalties to any
      third party, including, but not limited to, a sound recording copyright
      owner (e.g., a record label), a musical work copyright owner (e.g., a
      music publisher), a performing rights organization (e.g., ASCAP, BMI,
      SESAC, etc.) (a “PRO”), a sound recording PRO (e.g., SoundExchange), any
      unions or guilds, and engineers, producers or other royalty participants
      involved in the creation of User Content.<br />
      Specific Rules for Musical Works and for Recording Artists. If you are a
      composer or author of a musical work and are affiliated with a PRO, then
      you must notify your PRO of the royalty-free license you grant through
      these Terms in your User Content to us. You are solely responsible for
      ensuring your compliance with the relevant PRO’s reporting obligations. If
      you have assigned your rights to a music publisher, then you must obtain
      the consent of such music publisher to grant the royalty-free license(s)
      set forth in these Terms in your User Content or have such music publisher
      enter into these Terms with us. Just because you authored a musical work
      (e.g., wrote a song) does not mean you have the right to grant us the
      licenses in these Terms. If you are a recording artist under contract with
      a record label, then you are solely responsible for ensuring that your use
      of the Services is in compliance with any contractual obligations you may
      have to your record label, including if you create any new recordings
      through the Services that may be claimed by your label
      Through-To-The-Audience Rights . All of the rights you grant in your User
      Content in these Terms are provided on a through-to-the-audience basis,
      meaning the owners or operators of third party services will not have any
      separate liability to you or any other third party for User Content posted
      or used on such third party service via the Services.<br />
      Waiver of Rights to User Content. By posting User Content to or through
      the Services, you waive any rights to prior inspection or approval of any
      marketing or promotional materials related to such User Content. You also
      waive any and all rights of privacy, publicity, or any other rights of a
      similar nature in connection with your User Content, or any portion
      thereof, unless privacy setting is applied when you post such content. To
      the extent any moral rights are not transferable or assignable, you hereby
      waive and agree never to assert any and all moral rights, or to support,
      maintain or permit any action based on any moral rights that you may have
      in or with respect to any User Content you Post to or through the
      Services.<br />
      We also have the right to disclose your identity to any third party who is
      claiming that any User Content posted or uploaded by you to our Services
      constitutes a violation of their intellectual property rights, or of their
      right to privacy, and such disclosure will be pursuant to a legally
      binding court order.<br />
      We, or authorised third parties, reserve the right to cut, crop, edit or
      refuse to publish, your content at our or their sole discretion. We have
      the right to remove, disallow, block or delete any posting you make on our
      Services if, in our opinion, your post does not comply with the content
      standards set out at “Your Access to and Use of Our Services”above. In
      addition, we have the right – but not the obligation – in our sole
      discretion to remove, disallow, block or delete any User Content (i) that
      we consider to violate these Terms, or (ii) in response to complaints from
      other users or third parties, with or without notice and without any
      liability to you. As a result, we recommend that you save copies of any
      User Content that you post to the Services on your personal device(s) in
      the event that you want to ensure that you have permanent access to copies
      of such User Content. We do not guarantee the accuracy, integrity,
      appropriateness or quality of any User Content, and under no circumstances
      will we be liable in any way for any User Content.<br />
      We accept no liability in respect of any content submitted by users and
      published by us or by authorised third parties.<br />
      If you wish to complain about information and materials uploaded by other
      users, please contact us in Wyak APP Feedback in Settings or email to
      Email Address: wenext.wyak@gmail.com Before we can respond to your
      request, you may be required to verify your identity or your account
      details.<br />
      Wyak takes reasonable measures to expeditiously remove from our Services
      any infringing material that we become aware of. It is Wyak’s policy, in
      appropriate circumstances and at its discretion, to disable or terminate
      the accounts of users of the Services who repeatedly infringe copyrights
      or intellectual property rights of others.<br />
      While our own staff is continually working to develop and evaluate our own
      product ideas and features, we pride ourselves on paying close attention
      to the interests, feedback, comments, and suggestions we receive from the
      user community. If you choose to contribute by sending us or our employees
      any ideas for products, services, features, modifications, enhancements,
      content, refinements, technologies, content offerings (such as audio,
      visual, games, or other types of content), promotions, strategies, or
      product/feature names, or any related documentation, artwork, computer
      code, diagrams, or other materials (collectively “Feedback”), then
      regardless of what your accompanying communication may say, the following
      terms will apply, so that future misunderstandings can be avoided.
      Accordingly, by sending Feedback to us, you agree that:<br />
      Wyak has no obligation to review, consider, or implement your Feedback, or
      to return to you all or part of any Feedback for any reason;<br />
      Feedback is provided on a non-confidential basis, and we are not under any
      obligation to keep any Feedback you send confidential or to refrain from
      using or disclosing it in any way; and<br />
      You irrevocably grant us perpetual and unlimited permission to reproduce,
      distribute, create derivative works of, modify, publicly perform
      (including on a through-to-the-audience basis), communicate to the public,
      make available, publicly display, and otherwise use and exploit the
      Feedback and derivatives thereof for any purpose and without restriction,
      free of charge and without attribution of any kind, including by making,
      using, selling, offering for sale, importing, and promoting commercial
      products and services that incorporate or embody Feedback, whether in
      whole or in part, and whether as provided or as modified.<br />
      Users of the Services may be permitted to upload, post or transmit (such
      as via a stream) or otherwise make available content through the Services
      including, without limitation, any text, photographs, sound recordings and
      the musical works embodied therein, including sound recordings from your
      personal music library and ambient noise (“User Content”). Users of the
      Services may also extract all or any portion of User Content created by
      another user to produce additional User Content, including collaborative
      User Content with other users, that combine and intersperse User Content
      generated by more than one user. Users of the Services may also overlay
      music, graphics, stickers, Virtual Items and other elements provided by
      Wyak (“Wyak Elements”) onto this User Content and transmit this User
      Content through the Services. The information and materials in the User
      Content, including User Content that includes Wyak Elements, have not been
      verified or approved by us. The views expressed by other users on the
      Services (including through use of the virtual gifts) do not represent our
      views or values.<br />
      Whenever you access or use a feature that allows you to upload or transmit
      User Content through the Services (including via certain third party
      social media platforms such as Instagram, Facebook, YouTube, Twitter), or
      to make contact with other users of the Services, you must comply with the
      standards set out at “Your Access to and Use of Our Services” above. You
      may also choose to upload or transmit your User Content, including User
      Content that includes Wyak Elements, on sites or platforms hosted by third
      parties. If you decide to do this, you must comply with their content
      guidelines as well as with the standards set out at “Your Access to and
      Use of Our Services” above. As noted above, these features may not be
      available to all users of the Services, and we have no liability to you
      for limiting your right to certain features of the Services.<br />
      You warrant that any such contribution does comply with those standards,
      and you will be liable to us and indemnify us for any breach of that
      warranty. This means you will be responsible for any loss or damage we
      suffer as a result of your breach of warranty.<br />
      Any User Content will be considered non-confidential and non-proprietary.
      You must not post any User Content on or through the Services or transmit
      to us any User Content that you consider to be confidential or
      proprietary. When you submit User Content through the Services, you agree
      and represent that you own that User Content, or you have received all
      necessary permissions, clearances from, or are authorised by, the owner of
      any part of the content to submit it to the Services, to transmit it from
      the Services to other third party platforms, and/or adopt any third party
      content.<br />
      If you only own the rights in and to a sound recording, but not to the
      underlying musical works embodied in such sound recordings, then you must
      not post such sound recordings to the Services unless you have all
      permissions, clearances from, or are authorised by, the owner of any part
      of the content to submit it to the Services.<br />
      You or the owner of your User Content still own the copyright in User
      Content sent to us, but by submitting User Content via the Services, you
      hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
      fully transferable, perpetual worldwide licence to use, modify, adapt,
      reproduce, make derivative works of, publish and/or transmit, and/or
      distribute and to authorise other users of the Services and other
      third-parties to view, access, use, download, modify, adapt, reproduce,
      make derivative works of, publish and/or transmit your User Content in any
      format and on any platform, either now known or hereinafter invented.<br />
      You further grant us a royalty-free license to use your user name, image,
      voice, and likeness to identify you as the source of any of your User
      Content; provided, however, that your ability to provide an image, voice,
      and likeness may be subject to limitations due to age restrictions.<br />
      For the avoidance of doubt, the rights granted in the preceding paragraphs
      of this Section include, but are not limited to, the right to reproduce
      sound recordings (and make mechanical reproductions of the musical works
      embodied in such sound recordings), and publicly perform and communicate
      to the public sound recordings (and the musical works embodied therein),
      all on a royalty-free basis. This means that you are granting us the right
      to use your User Content without the obligation to pay royalties to any
      third party, including, but not limited to, a sound recording copyright
      owner (e.g., a record label), a musical work copyright owner (e.g., a
      music publisher), a performing rights organization (e.g., ASCAP, BMI,
      SESAC, etc.) (a “PRO”), a sound recording PRO (e.g., SoundExchange), any
      unions or guilds, and engineers, producers or other royalty participants
      involved in the creation of User Content.<br />
      Specific Rules for Musical Works and for Recording Artists. If you are a
      composer or author of a musical work and are affiliated with a PRO, then
      you must notify your PRO of the royalty-free license you grant through
      these Terms in your User Content to us. You are solely responsible for
      ensuring your compliance with the relevant PRO’s reporting obligations. If
      you have assigned your rights to a music publisher, then you must obtain
      the consent of such music publisher to grant the royalty-free license(s)
      set forth in these Terms in your User Content or have such music publisher
      enter into these Terms with us. Just because you authored a musical work
      (e.g., wrote a song) does not mean you have the right to grant us the
      licenses in these Terms. If you are a recording artist under contract with
      a record label, then you are solely responsible for ensuring that your use
      of the Services is in compliance with any contractual obligations you may
      have to your record label, including if you create any new recordings
      through the Services that may be claimed by your label
      Through-To-The-Audience Rights . All of the rights you grant in your User
      Content in these Terms are provided on a through-to-the-audience basis,
      meaning the owners or operators of third party services will not have any
      separate liability to you or any other third party for User Content posted
      or used on such third party service via the Services.<br />
      Waiver of Rights to User Content. By posting User Content to or through
      the Services, you waive any rights to prior inspection or approval of any
      marketing or promotional materials related to such User Content. You also
      waive any and all rights of privacy, publicity, or any other rights of a
      similar nature in connection with your User Content, or any portion
      thereof, unless privacy setting is applied when you post such content. To
      the extent any moral rights are not transferable or assignable, you hereby
      waive and agree never to assert any and all moral rights, or to support,
      maintain or permit any action based on any moral rights that you may have
      in or with respect to any User Content you Post to or through the
      Services.<br />
      We also have the right to disclose your identity to any third party who is
      claiming that any User Content posted or uploaded by you to our Services
      constitutes a violation of their intellectual property rights, or of their
      right to privacy, and such disclosure will be pursuant to a legally
      binding court order.<br />
      We, or authorised third parties, reserve the right to cut, crop, edit or
      refuse to publish, your content at our or their sole discretion. We have
      the right to remove, disallow, block or delete any posting you make on our
      Services if, in our opinion, your post does not comply with the content
      standards set out at “Your Access to and Use of Our Services”above. In
      addition, we have the right – but not the obligation – in our sole
      discretion to remove, disallow, block or delete any User Content (i) that
      we consider to violate these Terms, or (ii) in response to complaints from
      other users or third parties, with or without notice and without any
      liability to you. As a result, we recommend that you save copies of any
      User Content that you post to the Services on your personal device(s) in
      the event that you want to ensure that you have permanent access to copies
      of such User Content. We do not guarantee the accuracy, integrity,
      appropriateness or quality of any User Content, and under no circumstances
      will we be liable in any way for any User Content.<br />
      We accept no liability in respect of any content submitted by users and
      published by us or by authorised third parties.<br />
      If you wish to complain about information and materials uploaded by other
      users, please contact us in Wyak APP Feedback in Settings or email to
      Email Address: wenext.wyak@gmail.com Before we can respond to your
      request, you may be required to verify your identity or your account
      details.<br />
      Wyak takes reasonable measures to expeditiously remove from our Services
      any infringing material that we become aware of. It is Wyak’s policy, in
      appropriate circumstances and at its discretion, to disable or terminate
      the accounts of users of the Services who repeatedly infringe copyrights
      or intellectual property rights of others.<br />
      While our own staff is continually working to develop and evaluate our own
      product ideas and features, we pride ourselves on paying close attention
      to the interests, feedback, comments, and suggestions we receive from the
      user community. If you choose to contribute by sending us or our employees
      any ideas for products, services, features, modifications, enhancements,
      content, refinements, technologies, content offerings (such as audio,
      visual, games, or other types of content), promotions, strategies, or
      product/feature names, or any related documentation, artwork, computer
      code, diagrams, or other materials (collectively “Feedback”), then
      regardless of what your accompanying communication may say, the following
      terms will apply, so that future misunderstandings can be avoided.
      Accordingly, by sending Feedback to us, you agree that:<br />
      Wyak has no obligation to review, consider, or implement your Feedback, or
      to return to you all or part of any Feedback for any reason;<br />
      Feedback is provided on a non-confidential basis, and we are not under any
      obligation to keep any Feedback you send confidential or to refrain from
      using or disclosing it in any way; and<br />
      You irrevocably grant us perpetual and unlimited permission to reproduce,
      distribute, create derivative works of, modify, publicly perform
      (including on a through-to-the-audience basis), communicate to the public,
      make available, publicly display, and otherwise use and exploit the
      Feedback and derivatives thereof for any purpose and without restriction,
      free of charge and without attribution of any kind, including by making,
      using, selling, offering for sale, importing, and promoting commercial
      products and services that incorporate or embody Feedback, whether in
      whole or in part, and whether as provided or as modified.
    </p>
    <h2>8. Indemnity</h2>
    <p>
      You agree to defend, indemnify, and hold harmless Wyak, its parents,
      subsidiaries, and affiliates, and each of their respective officers,
      directors, employees, agents and advisors from any and all claims,
      liabilities, costs, and expenses, including, but not limited to,
      attorneys’ fees and expenses, arising out of a breach by you or any user
      of your account of these Terms or arising out of a breach of your
      obligations, representation and warranties under these Terms.
    </p>
    <h2>9. EXCLUSION OF WARRANTIES</h2>
    <p>
      NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CANNOT
      CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS ENTITLED TO
      AS A CONSUMER.<br />
      THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR
      REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT
      REPRESENT OR WARRANT TO YOU THAT:<br />
      YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;<br />
      YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE
      FROM ERROR;<br />
      ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES
      WILL BE ACCURATE OR RELIABLE;<br />
      AND DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO
      YOU AS PART OF THE SERVICES WILL BE CORRECTED.<br />
      NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED TERMS AS
      TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE WITH
      DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT THEY ARE
      EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND, WITHDRAW OR
      RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR BUSINESS
      AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE.<br />
    </p>
    <h2>10. LIMITATION OF LIABILITY</h2>
    <p>
      NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR LOSSES
      WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. THIS
      INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE
      OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS AND FOR FRAUD
      OR FRAUDULENT MISREPRESENTATION.<br />
      SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:<br />
      (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY);<br />
      (II) ANY LOSS OF GOODWILL;<br />
      (III) ANY LOSS OF OPPORTUNITY;<br />
      (IV) ANY LOSS OF DATA SUFFERED BY YOU;<br />
      (V) OR ANY INDIRECT OR CONSEQUENTIAL LOSSES WHICH MAY BE INCURRED BY YOU.
      ANY OTHER LOSS WILL BE LIMITED TO THE AMOUNT PAID BY YOU TO Wyak WITHIN
      THE LAST 12 MONTHS.<br />
      ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF:<br />
      ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR
      TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES
      WITHIN THE SERVICES);<br />
      not establish a link to our Services in any website that is not owned by
      you. The website in which you are linking must comply in all respects with
      the content standards set out at “Your Access to and Use of Our Services”
      above. We reserve the right to withdraw linking permission without
      notice.<br />
      No Waiver .Our failure to insist upon or enforce any provision of these
      Terms shall not be construed as a waiver of any provision or right.<br />
      Security. We do not guarantee that our Services will be secure or free
      from bugs or viruses. You are responsible for configuring your information
      technology, computer programmes and platform to access our Services. You
      should use your own virus protection software.<br />
      Severability. If any court of law, having jurisdiction to decide on this
      matter, rules that any provision of these Terms is invalid, then that
      provision will be removed from the Terms without affecting the rest of the
      Terms, and the remaining provisions of the Terms will continue to be valid
      and enforceable.<br />
      ARBITRATION AND CLASS ACTION WAIVER. This Section includes an arbitration
      agreement and an agreement that all claims will be brought only in an
      individual capacity (and not as a class action or other representative
      proceeding). Please read it carefully. You may opt out of the arbitration
      agreement by following the opt out procedure described below.<br />
      Informal Process First. You agree that in the event of any dispute between
      you and Wyak, you will first contact Wyak and make a good faith sustained
      effort to resolve the dispute before resorting to more formal means of
      resolution, including without limitation any court action.<br />
      Arbitration Agreement. After the informal dispute resolution process any
      remaining dispute, controversy, or claim (collectively, “Claim”) relating
      in any way to your use of Wyak’s services and/or products, including the
      Services, or relating in any way to the communications between you and
      Wyak or any other user of the Services, will be finally resolved by
      binding arbitration. This mandatory arbitration agreement applies equally
      to you and Wyak. However, this arbitration agreement does not (a) govern
      any Claim by Wyak for infringement of its intellectual property or access
      to the Services that is unauthorized or exceeds authorization granted in
      these Terms or (b) bar you from making use of applicable small claims
      court procedures in appropriate cases. If you are an individual you may
      opt out of this arbitration agreement within thirty (30) days of the first
      of the date you access or use this Services by following the procedure
      described below.<br />
      You agree that the Dubai International Arbitration Centre governs the
      interpretation and enforcement of this provision, and that you and Wyak
      are each waiving the right to a trial by jury or to participate in a class
      action. This arbitration provision will survive any termination of these
      Terms.<br />
      If you wish to begin an arbitration proceeding, after following the
      informal dispute resolution procedure, you must send a letter requesting
      arbitration and describing your claim to:<br />
      Email Address: wenext.wyak@gmail.com Attention: Arbitration<br />
      If you do not want to arbitrate disputes with Wyak and you are an
      individual, you may opt out of this arbitration agreement by sending an
      email to wenext.wyak@gmail.com (30) days of the first of the date you
      access or use the Services.<br />
      Class Action Waiver. Any Claim must be brought in the respective party’s
      individual capacity, and not as a plaintiff or class member in any
      purported class, collective, representative, multiple plaintiff, or
      similar proceeding (“Class Action”). The parties expressly waive any
      ability to maintain any Class Action in any forum. If the Claim is subject
      to arbitration, the arbitrator will not have authority to combine or
      aggregate similar claims or conduct any Class Action nor make an award to
      any person or entity not a party to the arbitration. Any claim that all or
      part of this Class Action Waiver is unenforceable, unconscionable, void,
      or voidable may be determined only by a court of competent jurisdiction
      and not by an arbitrator. The parties understand that any right to
      litigate in court, to have a judge or jury decide their case, or to be a
      party to a class or representative action, is waived, and that any claims
      must be decided individually, through arbitration.<br />
      If this class action waiver is found to be unenforceable, then the
      entirety of the Arbitration Agreement, if otherwise effective, will be
      null and void. The arbitrator may award declaratory or injunctive relief
      only in favor of the individual party seeking relief and only to the
      extent necessary to provide relief warranted by that party's individual
      claim. If for any reason a claim proceeds in court rather than in
      arbitration, you and Wyak each waive any right to a jury trial.<br />
      If a counter-notice is received by Wyak’s Copyright Agent, we may send a
      copy of the counter-notice to the original complaining party informing
      that person that we may replace the removed content or cease disabling it.
      Unless the original complaining party files an action seeking a court
      order against the Content Provider, member or user, the removed content
      may be replaced, or access to it restored, in ten business days or more
      after receipt of the counter-notice, at Wyak’s sole discretion.<br />
      Please understand that filing a counter-notification may lead to legal
      proceedings between you and the complaining party to determine ownership.
      Be aware that there may be adverse legal consequences in your country if
      you make a false or bad faith allegation by using this process.<br />
      California Consumer Rights Notice. Under California Civil Code Section
      1789.3, California users of the Services receive the following specific
      consumer rights notice: The Complaint Assistance Unit of the Division of
      Consumer Services of the California Department of Consumer Affairs may be
      contacted in writing at the contact information set forth at
      https://www.dca.ca.gov/about_us/contactus.shtml.<br />
      Users of the Services who are California residents and are under 18 years
      of age may request and obtain removal of User Content they posted by
      emailing us at wenext.wyak@gmail.com. All requests must be labeled
      "California Removal Request" on the email subject line. All requests must
      provide a description of the User Content you want removed and information
      reasonably sufficient to permit us to locate that User Content. We do not
      accept California Removal Requests via postal mail, telephone or
      facsimile. We are not responsible for notices that are not labeled or sent
      properly, and we may not be able to respond if you do not provide adequate
      information.<br />
      Exports. You agree that you will not export or re-export, directly or
      indirectly the Services and/or other information or materials provided by
      Wyak hereunder, to any country for which the United States or any other
      relevant jurisdiction requires any export license or other governmental
      approval at the time of export without first obtaining such license or
      approval. In particular, but without limitation, the Services may not be
      exported or re-exported (a) into any U.S. embargoed countries or any
      country that has been designated by the U.S. Government as a “terrorist
      supporting” country, or (b) to anyone listed on any U.S. Government list
      of prohibited or restricted parties, including the U.S. Treasury
      Department's list of Specially Designated Nationals or the U.S. Department
      of Commerce Denied Person’s List or Entity List.<br />
      U.S. Government Restricted Rights. The Services and related documentation
      are "Commercial Items", as that term is defined at 48 C.F.R. §2.101,
      consisting of "Commercial Computer Software" and "Commercial Computer
      Software Documentation", as such terms are used in 48 C.F.R. §12.212 or 48
      C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48
      C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial
      Computer Software and Commercial Computer Software Documentation are being
      licensed to U.S. Government end users (a) only as Commercial Items and (b)
      with only those rights as are granted to all other end users pursuant to
      the terms and conditions herein.<br />
      App Stores<br />
      To the extent permitted by applicable law, the following supplemental
      terms shall apply when accessing the Platform through specific devices:<br />
      Notice regarding Apple.<br />
      By downloading the Platform from a device made by Apple, Inc. (“Apple”) or
      from Apple’s App Store, you specifically acknowledge and agree that:<br />
      These Terms between Wyak and you; Apple is not a party to these Terms.<br />
      The license granted to you hereunder is limited to a personal, limited,
      non-exclusive, non-transferable right to install the Platform on the Apple
      device(s) authorised by Apple that you own or control for personal,
      non-commercial use, subject to the Usage Rules set forth in Apple’s App
      Store Terms of Services.<br />
      Apple is not responsible for the Platform or the content thereof and has
      no obligation whatsoever to furnish any maintenance or support services
      with respect to the Platform.<br />
      In the event of any failure of the Platform to conform to any applicable
      warranty, you may notify Apple, and Apple will refund the purchase price
      for the Platform, if any, to you. To the maximum extent permitted by
      applicable law, Apple will have no other warranty obligation whatsoever
      with respect to the Platform.<br />
      Apple is not responsible for addressing any claims by you or a third party
      relating to the Platform or your possession or use of the Platform,
      including without limitation (a) product liability claims; (b) any claim
      that the Platform fails to conform to any applicable legal or regulatory
      requirement; and (c) claims arising under consumer protection or similar
      legislation.<br />
      In the event of any third party claim that the Platform or your possession
      and use of the Platform infringes such third party’s intellectual property
      rights, Apple is not responsible for the investigation, defence,
      settlement or discharge of such intellectual property infringement
      claim.<br />
      You represent and warrant that (a) you are not located in a country that
      is subject to a U.S. Government embargo, or that has been designated by
      the U.S. Government as a “terrorist supporting” country; and (b) you are
      not listed on any U.S. Government list of prohibited or restricted
      parties.<br />
      Apple and its subsidiaries are third party beneficiaries of these Terms
      and upon your acceptance of the terms and conditions of these Terms, Apple
      will have the right (and will be deemed to have accepted the right) to
      enforce these Terms against you as a third party beneficiary hereof.<br />
      Wyak expressly authorises use of the Platform by multiple users through
      the Family Sharing or any similar functionality provided by Apple.<br />
      Google Play.<br />
      By downloading the Platform from Google Play (or its successors) operated
      by Google, Inc. or one of its affiliates (“Google”), you specifically
      acknowledge and agree that:<br />
      to the extent of any conflict between (a) the Google Play Terms of
      Services and the Google Play Business and Program Policies or such other
      terms which Google designates as default end user license terms for Google
      Play (all of which together are referred to as the “Google Play Terms”),
      and (b) the other terms and conditions in these Terms, the Google Play
      Terms shall apply with respect to your use of the Platform that you
      download from Google Play, and you hereby acknowledge that Google does not
      have any responsibility or liability related to compliance or
      non-compliance by Wyak or you (or any other user) under these Terms or the
      Google Play Terms.<br />
      Contact Us.<br />
      Email Address: wenext.wyak@gmail.com
    </p>
    <p></p>
    <p>
      <b>Terms of Service</b><br />
      (If you are a user having your usual residence in the EU)<br />
      <i>Last updated: JUN 2021</i>
    </p>
    <h2>1．Your Relationship With Us</h2>
    <p>
      Welcome to Wyak (the “Platform”), which is provided by Wenext Limited
      Address: LING NAN (HK) BUSINESS CO., LIMITED<br />
      Unit A3, 2/F., HING YIP CENTRE, NO.31 HING YIP STREET, KWUN TONG,
      KOWLOONHONG KONG, or one of our affiliates that may be specified in the
      Supplemental Terms – Jurisdiction-Specific below to the extent you access
      the Services from a specific jurisdiction (collectively such entities will
      be referred to as “Wyak”, “we” or “us”).<br />
      You are reading the Terms of Service (the “Terms”), which govern the
      relationship and serve as an agreement between you and us and set forth
      the terms and conditions by which you may access and use the Platform and
      our related websites, services, applications, products and content
      (collectively, the “Services”). Our Services are provided for private,
      non-commercial use. For purposes of these Terms, “you” and “your” means
      you as the user of the Services.<br />
      The Terms form a legally binding agreement between you and us. Please take
      the time to read them carefully.
    </p>
    <h2>2．Accepting the Terms</h2>
    <p>
      By accessing or using our Services, you confirm that you can form a
      binding contract with Wyak, that you accept these Terms that you agree to
      comply with them. Your access to and use of our Services is also subject
      to our and Community Guidelines, the terms of which can be found directly
      on the Platform, or where the Platform is made available for download, on
      your mobile device’s applicable app store, and are incorporated herein by
      reference.<br />
      If you access or use the Services from within a jurisdiction for which
      there are separate supplemental terms, you also hereby agree to the
      supplemental terms applicable to users in each jurisdiction as outlined
      below, and in the event of a conflict between the provisions of the
      Supplemental Terms – Jurisdiction-Specific that are relevant to your
      jurisdiction from which you access or use the Services, and the rest of
      these Terms, the relevant jurisdictions’ Supplemental Terms –
      Jurisdiction-Specific will supersede and control. If you do not agree to
      these Terms, you must not access or use our Services.<br />
      If you are accessing or using the Services on behalf of a business or
      entity, then (a) “you” and “your” includes you and that business or
      entity, (b) you represent and warrant that you are an authorized
      representative of the business or entity with the authority to bind the
      entity to these Terms, and that you agree to these Terms on the entity’s
      behalf, and (c) your business or entity is legally and financially
      responsible for your access or use of the Services as well as for the
      access or use of your account by others affiliated with your entity,
      including any employees, agents or contractors.<br />
      You can accept the Terms by accessing or using our Services. You
      understand and agree that we will treat your access or use of the Services
      as acceptance of the Terms from that point onwards.<br />
      You should print off or save a local copy of the Terms for your records.
    </p>
    <h2>3．Changes to the Terms</h2>
    <p>
      We amend these Terms from time to time, for instance when we update the
      functionality of our Services, when we combine multiple apps or services
      operated by us or our affiliates into a single combined service or app, or
      when there are regulatory changes. We will use commercially reasonable
      efforts to provide reasonable notice to all users of any material changes
      to these Terms, such as through a notice on our Platform, however, you
      should look at the Terms regularly to check for such changes. We will also
      update the “Last Updated” date at the top of these Terms, which reflect
      the effective date of such Terms. Your continued access or use of the
      Services after the date of the new Terms constitutes your acceptance of
      the new Terms. If you do not agree to the new Terms, you must stop
      accessing or using the Services.
    </p>
    <h2>4．Your Account with Us</h2>
    <p>
      To access or use some of our Services, you must create an account with us.
      When you create this account, you must provide accurate and up-to-date
      information. It is important that you maintain and promptly update your
      details and any other information you provide to us, to keep such
      information current and complete.<br />
      It is important that you keep your account password confidential and that
      you do not disclose it to any third party. If you know or suspect that any
      third party knows your password or has accessed your account, you can
      reset your password by receiving password recovery SMS via your registered
      phone number and promptly notify us at Email Address:
      wenext.wyak@gmail.com<br />
      You agree that you are solely responsible (to us and to others) for the
      activity that occurs under your account.<br />
      We reserve the right to disable your user account at any time, including
      if in our reasonable opinion you have failed to comply with any of the
      provisions of these Terms, or if activities occur on your account which,
      in our sole discretion, would or might cause damage to or impair the
      Services or infringe or violate any third party rights, or violate any
      applicable laws or regulations.<br />
      If you no longer want to use our Services again, and would like your
      account deleted, we can take care of this for you. Please contact us Email
      Address: wenext.wyak@gmail.com<br />
      , and we will provide you with further assistance and guide you through
      the process. Please be minded that once you choose to delete your account,
      you will not be able to reactivate your account or retrieve any of the
      content or information you have added.
    </p>
    <h2>5．Your use of our services</h2>
    <p>
      Your access to and use of the Services is subject to these Terms and all
      applicable laws and regulations. You may not:<br />
      access or use the Services if you are not over 16 or otherwise able to
      agree to these Terms;<br />
      to the greatest extent permitted under applicable law, make unauthorised
      copies, modify, adapt, translate, reverse engineer, disassemble, decompile
      or create any derivative works based on the Services, including any files,
      tables or documentation (or any portion thereof) or determine or attempt
      to determine any source code, algorithms, methods or techniques embodied
      the Platform or any derivative works thereof;distribute, license,
      transfer, or sell, in whole or in part, any of the Services or any
      derivative works thereof;market, rent or lease the Services for a fee or
      charge, or use the Platform to advertise or perform any commercial
      solicitation; use the Services, without our express written consent, for
      any commercial or unauthorized purpose, including communicating or
      facilitating any commercial advertisement or solicitation or
      spamming;interfere with or attempt to interfere with the proper working of
      the Services, disrupt our website or any networks connected to the
      Services, or bypass any measures we may use to prevent or restrict access
      to the Services;incorporate the Platform or any portion thereof into any
      other program or product. In such case, we reserve the right to refuse
      service, terminate accounts or limit access to the Services in our sole
      discretion;use automated scripts to collect information from or otherwise
      interact with the Services;impersonate any person or entity, or falsely
      state or otherwise misrepresent you or your affiliation with any person or
      entity, including giving the impression that any content you upload, post,
      transmit, distribute or otherwise make available emanates from the
      Services;intimidate or harass another, or promote sexually explicit
      material, violence or discrimination based on race, sex, religion,
      nationality, disability, sexual orientation or age;use or attempt to use
      another’s account, service or system without authorisation from Wyak, or
      create a false identity on the Services;use the Services in a manner that
      may create a conflict of interest or undermine the purposes of the
      Services, such as trading reviews with other users or writing or
      soliciting shill reviews;use the Services to upload, transmit, distribute,
      store or otherwise make available in any way:files that contain viruses,
      trojans, worms, logic bombs or other material that is malicious or
      technologically harmful;any unsolicited or unauthorised advertising,
      solicitations, promotional materials, “junk mail,” “spam,” “chain
      letters,” “pyramid schemes,” or any other prohibited form of solicitation;
      any private information of any third party, including addresses, phone
      numbers, email addresses, number and feature in the personal identity
      document (e.g., National Insurance numbers, passport numbers) or credit
      card numbers; any material which does or may infringe any copyright, trade
      mark or other intellectual property or privacy rights of any other
      person;any material which is defamatory of any person, obscene, offensive,
      pornographic, hateful or inflammatory;any material that would constitute,
      encourage or provide instructions for a criminal offence, dangerous
      activities or self-harm;any material that is deliberately designed to
      provoke or antagonise people, especially trolling and bullying, or is
      intended to harass, harm, hurt, scare, distress, embarrass or upset
      people;any material that contains a threat of any kind, including threats
      of physical violence;any material that is racist or discriminatory,
      including discrimination on the basis of someone’s race, religion, age,
      gender, disability or sexuality;any answers, responses, comments,
      opinions, analysis or recommendations that you are not properly licensed
      or otherwise qualified to provide;material that, in the sole judgment of
      Wyak, is objectionable or which restricts or inhibits any other person
      from using the Services, or which may expose Wyak, the Services or its
      users to any harm or liability of any type;<br />
      or In addition to the above, your access to and use of the Services must,
      at all times, be compliant with our Community Guidelines.<br />
      We reserve the right, at any time and without prior notice, to remove or
      disable access to content at our discretion for any reason or no reason.
      Some of the reasons we may remove or disable access to content may include
      finding the content objectionable, in violation of these Terms or our
      Community Guidelines, or otherwise harmful to the Services or our users.
    </p>
    <h2>6．Intellectual Property Rights</h2>
    <p>
      We respect intellectual property rights and ask you to do the same. As a
      condition of your access to and use of the Services, you agree not to use
      the Services to infringe on any intellectual property rights, for example,
      you must not upload any content owned by anyone else to the Platform. We
      reserve the right, with or without notice, at any time and in our sole
      discretion to block access to and/or terminate the accounts of any user
      who infringes or is alleged to infringe any copyrights or other
      intellectual property rights.
    </p>
    <h2>7．Content</h2>
    <p>
      Wyak Content<br />
      As between you and Wyak, all content, software, images, text, graphics,
      illustrations, logos, patents, trademarks, service marks, copyrights,
      photographs, audio, music on and “look and feel” of the Services, and all
      intellectual property rights related thereto (the “Wyak Content”), are
      either owned or licensed by Wyak, it being understood that you or your
      licensors will own any User Content you upload or transmit through the
      Services. Use of the Wyak Content or materials on the Services for any
      purpose not expressly permitted by these Terms is strictly prohibited.
      Such content may not be downloaded, copied, reproduced, distributed,
      transmitted, broadcast, displayed, sold, licensed or otherwise exploited
      for any purpose whatsoever without our or, where applicable, our
      licensors’ prior written consent. We and our licensors reserve all rights
      not expressly granted in and to their content.<br />
      You acknowledge and agree that we may generate revenues, increase goodwill
      or otherwise increase our value from your use of the Services, including,
      by way of example and not limitation, through Virtual Items and
      value-added services, and except as specifically permitted by us in these
      Terms or in another agreement you enter into with us, you will have no
      right to share in any such revenue, goodwill or value whatsoever. You
      further acknowledge that, except as specifically permitted by us in these
      Terms or in another agreement you enter into with us, you (i) have no
      right to receive any income or other consideration from any User Content
      or your use of any musical works, sound recordings or audiovisual clips
      made available to you on or through the Services, including in any User
      Content created by you, and (ii) are prohibited from exercising any rights
      to monetize or obtain consideration from any User Content within the
      Services or on any third party service (e.g. , you cannot claim User
      Content that has been uploaded to a social media platform such as YouTube
      for monetization).<br />
      Subject to the terms and conditions of the Terms, you are hereby granted a
      non-exclusive, limited, non-transferable, non-sublicensable, revocable,
      worldwide license to access and use the Services, including to download
      the Platform on a permitted device, and to access the Wyak Content solely
      for your personal, non-commercial use through your use of the Services and
      solely in compliance with these Terms. Wyak reserves all rights not
      expressly granted herein in the Services and the Wyak Content. You
      acknowledge and agree that Wyak may terminate this license at any time for
      any reason or no reason.<br />
      NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE MUSICAL
      WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE FROM OR THROUGH THE
      SERVICE.<br />
      You acknowledge and agree that when you view content provided by others on
      the Services, you are doing so at your own risk. The content on our
      Services is provided for general information only. It is not intended to
      amount to advice on which you should rely. You must obtain professional or
      specialist advice before taking, or refraining from, any action on the
      basis of the content on our Services.<br />
      We make no representations, warranties or guarantees, whether express or
      implied, that any Wyak Content (including User Content) is accurate,
      complete or up to date. Where our Services contain links to other sites
      and resources provided by third parties, these links are provided for your
      information only. We have no control over the contents of those sites or
      resources. Such links should not be interpreted as approval by us of those
      linked websites or information you may obtain from them. You acknowledge
      that we have no obligation to pre-screen, monitor, review, or edit any
      content posted by you and other users on the Platform (including User
      Content).<br />
      User-Generated Content<br />
      Users of the Services may be permitted to upload, post or transmit (such
      as via a stream) or otherwise make available content through the Services
      including, without limitation, any text, photographs, user videos, sound
      recordings and the musical works embodied therein, including videos that
      incorporate locally stored sound recordings from your personal music
      library and ambient noise (“User Content”). Users of the Services may also
      extract all or any portion of User Content created by another user to
      produce additional User Content, including collaborative User Content with
      other users, that combine and intersperse User Content generated by more
      than one user. Users of the Services may also overlay music, graphics,
      stickers, Virtual Items and other elements provided by Wyak (“Wyak
      Elements”) onto this User Content and transmit this User Content through
      the Services. The information and materials in the User Content, including
      User Content that includes Wyak Elements, have not been verified or
      approved by us. The views expressed by other users on the Services
      (including through use of the virtual gifts) do not represent our views or
      values.<br />
      Whenever you access or use a feature that allows you to upload or transmit
      User Content through the Services (including via certain third party
      social media platforms such as Instagram, Facebook, YouTube, Twitter), or
      to make contact with other users of the Services, you must comply with the
      standards set out at Section 5 above. You may also choose to upload or
      transmit your User Content, including User Content that includes Wyak
      Elements, on sites or platforms hosted by third parties. If you decide to
      do this, you must comply with their content guidelines as well as with the
      standards set out in this Section 7 above.<br />
      You warrant that any such contribution does comply with those standards,
      and you will be liable to us and indemnify us for any breach of that
      warranty. This means you will be responsible for any loss or damage we
      suffer as a result of your breach of warranty.<br />
      Any User Content will be considered non-confidential and non-proprietary.
      You must not post any User Content on or through the Services or transmit
      to us any User Content that you consider to be confidential or
      proprietary. When you submit User Content through the Services, you agree
      and represent that you own that User Content, or you have received all
      necessary permissions, clearances from, or are authorised by, the owner of
      any part of the content to submit it to the Services, to transmit it from
      the Services to other third party platforms, and/or adopt any third party
      content.<br />
      If you only own the rights in and to a sound recording, but not to the
      underlying musical works embodied in such sound recordings, then you must
      not post such sound recordings to the Services unless you have all
      permissions, clearances from, or are authorised by, the owner of any part
      of the content to submit it to the Services .<br />
      You or the owner of your User Content still own the copyright in User
      Content sent to us, but by submitting User Content via the Services, you
      hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
      fully transferable, perpetual worldwide licence to use, modify, adapt,
      reproduce, make derivative works of, publish and/or transmit, and/or
      distribute and to authorise others users of the Services and other
      third-parties to view, access, use, download, modify, adapt, reproduce,
      make derivative works of, publish and/or transmit your User Content in any
      format and on any platform, either now known or hereinafter invented.<br />
      You further grant us a royalty-free license to use your user name, image,
      voice, and likeness to identify you as the source of any of your User
      Content.<br />
      For the avoidance of doubt, the rights granted in the preceding paragraphs
      of this Section include, but are not limited to, the right to reproduce
      sound recordings (and make mechanical reproductions of the musical works
      embodied in such sound recordings), and publicly perform and communicate
      to the public sound recordings (and the musical works embodied therein),
      all on a royalty-free basis. This means that you are granting us the right
      to Use your User Content without the obligation to pay royalties to any
      third party, including, but not limited to, a sound recording copyright
      owner ( e.g. , a record label), a musical work copyright owner ( e.g. , a
      music publisher), a performing rights organization ( e.g. , ASCAP, BMI,
      SESAC, etc.) (a “ PRO ”), a sound recording PRO (e.g. , SoundExchange),
      any unions or guilds, and engineers, producers or other royalty
      participants involved in the creation of User Content.<br />
      Specific Rules for Musical Works and for Recording Artists.If you are a
      composer or author of a musical work and are affiliated with a PRO, then
      you must notify your PRO of the royalty-free license you grant through
      these Terms in your User Content to us. You are solely responsible for
      ensuring your compliance with the relevant PRO’s reporting obligations. If
      you have assigned your rights to a music publisher, then you must obtain
      the consent of such music publisher to grant the royalty-free license(s)
      set forth in these Terms in your User Content or have such music publisher
      enter into these Terms with us. Just because you authored a musical work (
      e.g. , wrote a song) does not mean you have the right to grant us the
      licenses in these Terms. If you are a recording artist under contract with
      a record label, then you are solely responsible for ensuring that your use
      of the Services is in compliance with any contractual obligations you may
      have to your record label, including if you create any new recordings
      through the Services that may be claimed by your label.<br />
      Through-To-The-Audience Rights.All of the rights you grant in your User
      Content in these Terms are provided on a through-to-the-audience basis,
      meaning the owners or operators of third party services will not have any
      separate liability to you or any other third party for User Content posted
      or used on such third party service via the Services.<br />
      Waiver of Rights to User Content.By posting User Content to or through the
      Services, you waive any rights to prior inspection or approval of any
      marketing or promotional materials related to such User Content. You also
      waive any and all rights of privacy, publicity, or any other rights of a
      similar nature in connection with your User Content, or any portion
      thereof, unless privacy setting is applied when you post such content. To
      the extent any moral rights are not transferable or assignable, you hereby
      waive and agree never to assert any and all moral rights, or to support,
      maintain or permit any action based on any moral rights that you may have
      in or with respect to any User Content you Post to or through the
      Services.<br />
      In certain circumstances, we also have the right to disclose your identity
      to any third party who is claiming that any User Content posted or
      uploaded by you to our Services constitutes a violation of their
      intellectual property rights, or of their right to privacy, and such
      disclosure will be pursuant to a legally binding court order.<br />
      We, or authorised third parties, reserve the right to cut, crop, edit or
      refuse to publish, your content at our or their sole discretion. We have
      the right to remove, disallow, block or delete any posting you make on our
      Platform if, in our opinion, your post does not comply with the content
      standards set out at Section 5 (Your Use of Our Services) above. In
      addition, we have the right – but not the obligation – in our sole
      discretion to remove, disallow, block or delete any User Content (i) that
      we consider to violate these Terms, or (ii) in response to complaints from
      other users or third parties, with or without notice and without any
      liability to you. As a result, we recommend that you save copies of any
      User Content that you post to the Services on your personal device(s) in
      the event that you want to ensure that you have permanent access to copies
      of such User Content. We do not guarantee the accuracy, integrity,
      appropriateness or quality of any User Content, and under no circumstances
      will we be liable in any way for any User Content.<br />
      You control whether your User Content is made publicly available on the
      Services to all other users of the Services or only available to people
      you approve. To change the default access setting for how your User
      Content is made available to other users, you should select the privacy
      setting available within the Apps.<br />
      We accept no liability in respect of any content submitted by users and
      published by us or by authorised third parties.<br />
      If you wish to complain about information and materials uploaded by other
      users, please contact us in Wyak APP Feedback in Settings or email to
      wenext.wyak@gmail.com, or write to us at wenext Limited<br />
      Address: LING NAN (HK) BUSINESS CO., LIMITED<br />
      Unit A3, 2/F., HING YIP CENTRE, NO.31 HING YIP STREET, KWUN TONG,
      KOWLOONHONG KONG<br />
      Before we can respond to your request, you may be required to verify your
      identity or your account details.<br />
      Wyak takes reasonable measures to expeditiously remove from our Services
      any infringing material that we become aware of. It is Wyak’s policy, in
      appropriate circumstances and at its discretion, to disable or terminate
      the accounts of users of the Services who repeatedly infringe copyrights
      or intellectual property rights of others.<br />
      While our own staff is continually working to develop and evaluate our own
      product ideas and features, we pride ourselves on paying close attention
      to the interests, feedback, comments, and suggestions we receive from the
      user community. If you choose to contribute by sending us or our employees
      any ideas for products, services, features, modifications, enhancements,
      content, refinements, technologies, content offerings (such as audio,
      visual, games, or other types of content), promotions, strategies, or
      product/feature names, or any related documentation, artwork, computer
      code, diagrams, or other materials (collectively “ Feedback ”), then
      regardless of what your accompanying communication may say, the following
      terms will apply, so that future misunderstandings can be avoided.
      Accordingly, by sending Feedback to us , you agree that:<br />
      we have no obligation to review, consider, or implement your Feedback, or
      to return to you all or part of any Feedback for any reason;<br />
      Feedback is provided on a non-confidential basis, and we are not under any
      obligation to keep any Feedback you send confidential or to refrain from
      using or disclosing it in any way;<br />
      and You irrevocably grant us perpetual and unlimited permission to
      reproduce, distribute, create derivative works of, modify, publicly
      perform (including on a through-to-the-audience basis), communicate to the
      public, make available, publicly display, and otherwise use and exploit
      the Feedback and derivatives thereof for any purpose and without
      restriction, free of charge and without attribution of any kind, including
      by making, using, selling, offering for sale, importing, and promoting
      commercial products and services that incorporate or embody Feedback,
      whether in whole or in part, and whether as provided or as modified.
    </p>
    <h2>8．Indemnity</h2>
    <p>
      You agree to defend, indemnify, and hold harmless Wyak, its parents,
      subsidiaries, and affiliates, and each of their respective officers,
      directors, employees, agents and advisors from any and all claims,
      liabilities, costs, and expenses, including, but not limited to,
      attorneys’ fees and expenses, arising out of a breach by you or any user
      of your account of these Terms or arising out of a breach of your
      obligations, representation and warranties under these Terms.
    </p>
    <h2>9．EXCLUSION OF WARRANTIES</h2>
    <p>
      NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CANNOT
      CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS ENTITLED TO
      AS A CONSUMER.<br />
      THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR
      REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT
      REPRESENT OR WARRANT TO YOU THAT:<br />
      YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;<br />
      YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE
      FROM ERROR;<br />
      ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES
      WILL BE ACCURATE OR RELIABLE;<br />
      AND DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO
      YOU AS PART OF THE SERVICES WILL BE CORRECTED<br />
      NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED TERMS AS
      TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE WITH
      DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT THEY ARE
      EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND, WITHDRAW OR
      RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR BUSINESS
      AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE.<br />
    </p>
    <h2>10．LIMITATION OF LIABILITY</h2>
    <p>
      NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR LOSSES
      WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. THIS
      INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE
      OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS AND FOR FRAUD
      OR FRAUDULENT MISREPRESENTATION.<br />
      SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:(I) ANY
      LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY); (II) ANY LOSS OF
      GOODWILL; (III) ANY LOSS OF OPPORTUNITY; (IV) ANY LOSS OF DATA SUFFERED BY
      YOU; OR (V) ANY INDIRECT OR CONSEQUENTIAL LOSSES WHICH MAY BE INCURRED BY
      YOU. ANY OTHER LOSS WILL BE LIMITED TO THE AMOUNT PAID BY YOU TO Wyak
      WITHIN THE LAST 12 MONTHS.ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU
      AS A RESULT OF: ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY
      PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY
      FEATURES WITHIN THE SERVICES);THE DELETION OF, CORRUPTION OF, OR FAILURE
      TO STORE, ANY CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR
      TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;YOUR FAILURE TO PROVIDE
      US WITH ACCURATE ACCOUNT INFORMATION; ORYOUR FAILURE TO KEEP YOUR PASSWORD
      OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.<br />
      PLEASE NOTE THAT WE ONLY PROVIDE OUR PLATFORM FOR DOMESTIC AND PRIVATE
      USE. YOU AGREE NOT TO USE OUR PLATFORM FOR ANY COMMERCIAL OR BUSINESS
      PURPOSES, AND WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF
      BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION, BUSINESS INTERRUPTION,
      OR LOSS OF BUSINESS OPPORTUNITY.<br />
      IF DEFECTIVE DIGITAL CONTENT THAT WE HAVE SUPPLIED DAMAGES A DEVICE OR
      DIGITAL CONTENT BELONGING TO YOU AND THIS IS CAUSED BY OUR FAILURE TO USE
      REASONABLE CARE AND SKILL, WE WILL EITHER REPAIR THE DAMAGE OR PAY YOU
      COMPENSATION. HOWEVER, WE WILL NOT BE LIABLE FOR DAMAGE THAT YOU COULD
      HAVE AVOIDED BY FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU
      FREE OF CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY
      FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM
      REQUIREMENTS ADVISED BY US.<br />
      THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT WE
      HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY
      SUCH LOSSES ARISING.<br />
      YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF
      OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF YOU’RE UNSURE
      WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE
      USING THE SERVICE.<br />
      TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY
      THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING, BY WAY OF
      EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER OR OTHER USER, IS
      DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US
      AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL
      AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING
      OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
    </p>
    <h2>11．Other Terms</h2>
    <p>
      Applicable Law and Jurisdiction for users in the European Union. These
      Terms, their subject matter and their formation, are governed by the laws
      of Hong Kong subject only to any mandatory provisions of consumer law in
      the country in which you reside. The United Nations Convention on
      Contracts for the International Sale of Goods as well as any other similar
      law, regulation or statute in effect in any other jurisdiction shall not
      apply. You and Wyak irrevocably agree that the courts of the country in
      which you reside shall have non-exclusive jurisdiction to settle any
      dispute or claim (including non-contractual disputes or claims) arising
      out of or in connection with this Agreement or its subject matter or
      formation. Alternatively, you may raise the dispute with an alternative
      dispute resolution body via the<br />
      Entire Agreement. These Terms (including the Supplemental Terms below)
      constitute the whole legal agreement between you and Wyak and govern your
      use of the Services and completely replace any prior agreements between
      you and Wyak in relation to the Services.<br />
      Links. You may link to our home page, provided you do so in a way that is
      fair and legal and does not damage our reputation or take advantage of it.
      You must not establish a link in such a way as to suggest any form of
      association, approval or endorsement on our part where none exists. You
      must not establish a link to our Platform in any website that is not owned
      by you. The website in which you are linking must comply in all respects
      with the content standards set out at Section 5 (Your Use of Our Services)
      above. We reserve the right to withdraw linking permission without
      notice.<br />
      Age Limit. The Platform is only for people 16 years old and over unless
      they have the consent of a parent or legal guardian. By using the
      Platform, you confirm that you are over the relevant age specified above.
      If we learn that someone under the relevant age specified above is using
      the Platform, we will terminate that user’s account. In addition, if you
      are under the relevant age specified above, you confirm that you possess
      legal parental or guardian consent for accessing or using the Platform,
      and are fully able and competent to enter into, abide by, and comply with
      the Terms No Waiver. Our failure to insist upon or enforce any provision
      of these Terms shall not be construed as a waiver of any provision or
      right.<br />
      Security. We do not guarantee that our Platform will be secure or free
      from bugs or viruses. You are responsible for configuring your information
      technology, computer programmes and platform to access our Platform. You
      should use your own virus protection software.<br />
      Severability. If any court of law, having jurisdiction to decide on this
      matter, rules that any provision of these Terms is invalid, then that
      provision will be removed from the Terms without affecting the rest of the
      Terms, and the remaining provisions of the Terms will continue to be valid
      and enforceable.<br />
      Any Questions?Get in touch at Email Address: wenext.wyak@gmail.com<br />
      Supplemental Terms – App Stores<br />
      To the extent permitted by applicable law, the following supplemental
      terms shall apply:<br />
      Notice regarding Apple. By accessing the Platform through a device made by
      Apple, Inc. (“Apple”), you specifically acknowledge and agree that:<br />
      These Terms between Wyak and you; Apple is not a party to these Terms.<br />
      The license granted to you hereunder is limited to a personal, limited,
      non-exclusive, non-transferable right to install the Platform on the Apple
      device(s) authorised by Apple that you own or control for personal,
      non-commercial use, subject to the Usage Rules set forth in Apple’s App
      Store Terms of Services.<br />
      Apple is not responsible for the Platform or the content thereof and has
      no obligation whatsoever to furnish any maintenance or support services
      with respect to the Platform.<br />
      In the event of any failure of the Platform to conform to any applicable
      warranty, you may notify Apple, and Apple will refund the purchase price
      for the Platform, if any, to you. To the maximum extent permitted by
      applicable law, Apple will have no other warranty obligation whatsoever
      with respect to the Platform.Apple is not responsible for addressing any
      claims by you or a third party relating to the Platform or your possession
      or use of the Platform, including without limitation (a) product liability
      claims; (b) any claim that the Platform fails to conform to any applicable
      legal or regulatory requirement; and (c) claims arising under consumer
      protection or similar legislation.<br />
      In the event of any third party claim that the Platform or your possession
      and use of the Platform infringes such third party’s intellectual property
      rights, Apple is not responsible for the investigation, defence,
      settlement or discharge of such intellectual property infringement
      claim.<br />
      You represent and warrant that (a) you are not located in a country that
      is subject to a U.S. Government embargo, or that has been designated by
      the U.S. Government as a “terrorist supporting” country; and (b) you are
      not listed on any U.S. Government list of prohibited or restricted
      parties.<br />
      Apple and its subsidiaries are third party beneficiaries of these Terms
      and upon your acceptance of the terms and conditions of these Terms, Apple
      will have the right (and will be deemed to have accepted the right) to
      enforce these Terms against you as a third party beneficiary hereof.<br />
      Wyak expressly authorises use of the Platform by multiple users through
      the Family Sharing or any similar functionality provided by Apple.<br />
      Google Play.By downloading the Platform from Google Play (or its
      successors) operated by Google, Inc. or one of its affiliates (“Google”),
      you specifically acknowledge and agree that:<br />
      to the extent of any conflict between (a) the Google Play Terms of
      Services and the Google Play Business and Program Policies or such other
      terms which Google designates as default end user license terms for Google
      Play (all of which together are referred to as the “Google Play Terms”),
      and (b) the other terms and conditions in these Terms, the Google Play
      Terms shall apply with respect to your use of the Platform that you
      download from Google Play,you hereby acknowledge that Google does not have
      any responsibility or liability related to compliance or non-compliance by
      Wyak or you (or any other user) under these Terms or the Google Play
      Terms.
    </p>
    <p>
      <b>Terms of Service</b><br />
      (If your residence is in another country, and not the US or EU)<br />
      <i>Last updated: JUN 2021</i>
    </p>
    <h2>1.Your Relationship With Us</h2>
    <p>
      Welcome to Wyak (the “Platform”), which is provided by WeNext Limited or
      one of its affiliates (“Wyak”, “we” or “us”).<br />
      You are reading the Terms of Service (the “Terms”), which govern the
      relationship and serve as an agreement between you and us and set forth
      the terms and conditions by which you may access and use the Platform and
      our related websites, services, applications, products and content
      (collectively, the “Services”). Our Services are provided for private,
      non-commercial use. For purposes of these Terms, “you” and “your” means
      you as the user of the Services.<br />
      The Terms form a legally binding agreement between you and us. Please take
      the time to read them carefully.<br />
    </p>
    <h2>2．Accepting the Terms</h2>
    <p>
      By accessing or using our Services, you confirm that you can form a
      binding contract with Wyak, that you accept these Terms and that you agree
      to comply with them. Your access to and use of our Services is also
      subject to our and Community Guidelines, the terms of which can be found
      directly on the Platform, or where the Platform is made available for
      download, on your mobile device’s applicable app store, and are
      incorporated herein by reference. By using the Services, you consent to
      the terms of the Terms of Service.<br />
      If you access or use the Services from within a jurisdiction for which
      there are separate supplemental terms, you also hereby agree to the
      supplemental terms applicable to users in each jurisdiction as outlined
      below, and in the event of a conflict between the provisions of the that
      are relevant to your jurisdiction from which you access or use the
      Services, and the rest of these Terms, the relevant jurisdictions’ will
      supersede and control. If you do not agree to these Terms, you must not
      access or use our Services.<br />
      If you are accessing or using the Services on behalf of a business or
      entity, then (a) “you” and “your” includes you and that business or
      entity, (b) you represent and warrant that you are an authorized
      representative of the business or entity with the authority to bind the
      entity to these Terms, and that you agree to these Terms on the entity’s
      behalf, and (c) your business or entity is legally and financially
      responsible for your access or use of the Services as well as for the
      access or use of your account by others affiliated with your entity,
      including any employees, agents or contractors.<br />
      You can accept the Terms by accessing or using our Services. You
      understand and agree that we will treat your access or use of the Services
      as acceptance of the Terms from that point onwards.<br />
      You should print off or save a local copy of the Terms for your records.
    </p>
    <h2>3．Changes to the Terms</h2>
    <p>
      We amend these Terms from time to time, for instance when we update the
      functionality of our Services, when we combine multiple apps or services
      operated by us or our affiliates into a single combined service or app, or
      when there are regulatory changes. We will use commercially reasonable
      efforts to generally notify all users of any material changes to these
      Terms, such as through a notice on our Platform, however, you should look
      at the Terms regularly to check for such changes. We will also update the
      “Last Updated” date at the top of these Terms, which reflect the effective
      date of such Terms. Your continued access or use of the Services after the
      date of the new Terms constitutes your acceptance of the new Terms. If you
      do not agree to the new Terms, you must stop accessing or using the
      Services.
    </p>
    <h2>4．Your Account with Us</h2>
    <p>
      To access or use some of our Services, you must create an account with us.
      When you create this account, you must provide accurate and up-to-date
      information. It is important that you maintain and promptly update your
      details and any other information you provide to us, to keep such
      information current and complete.<br />
      It is important that you keep your account password confidential and that
      you do not disclose it to any third party. If you know or suspect that any
      third party knows your password or has accessed your account, you can
      reset your password by receiving password recovery SMS via your registered
      phone number and promptly notify us at Emai Address:
      wenext.wyak@gmail.com<br />
      You agree that you are solely responsible (to us and to others) for the
      activity that occurs under your account.<br />
      We reserve the right to disable your user account at any time, including
      if you have failed to comply with any of the provisions of these Terms, or
      if activities occur on your account which, in our sole discretion, would
      or might cause damage to or impair the Services or infringe or violate any
      third party rights, or violate any applicable laws or regulations.<br />
      If you no longer want to use our Services again, and would like your
      account deleted, we can take care of this for you. Please contact us via
      account wenext.wyak@gmail.com<br />
      , and we will provide you with further assistance and guide you through
      the process. Once you choose to delete your account, you will not be able
      to reactivate your account or retrieve any of the content or information
      you have added.
    </p>
    <h2>5．Your Access to and Use of Our Services</h2>
    <p>
      Your access to and use of the Services is subject to these Terms and all
      applicable laws and regulations. You may not:<br />
      access or use the Services if you are not fully able and legally competent
      to agree to these Terms;<br />
      make unauthorised copies, modify, adapt, translate, reverse engineer,
      disassemble, decompile or create any derivative works of the Services or
      any content included therein, including any files, tables or documentation
      (or any portion thereof) or determine or attempt to determine any source
      code, algorithms, methods or techniques embodied by the Services or any
      derivative works thereof;<br />
      distribute, license, transfer, or sell, in whole or in part, any of the
      Services or any derivative works thereof;<br />
      market, rent or lease the Services for a fee or charge, or use the
      Services to advertise or perform any commercial solicitation;<br />
      use the Services, without our express written consent, for any commercial
      or unauthorized purpose, including communicating or facilitating any
      commercial advertisement or solicitation or spamming;<br />
      interfere with or attempt to interfere with the proper working of the
      Services, disrupt our website or any networks connected to the Services,
      or bypass any measures we may use to prevent or restrict access to the
      Services;<br />
      incorporate the Services or any portion thereof into any other program or
      product. In such case, we reserve the right to refuse service, terminate
      accounts or limit access to the Services in our sole discretion;use
      automated scripts to collect information from or otherwise interact with
      the Services;impersonate any person or entity, or falsely state or
      otherwise misrepresent you or your affiliation with any person or entity,
      including giving the impression that any content you upload, post,
      transmit, distribute or otherwise make available emanates from the
      Services;use or attempt to use another’s account, service or system
      without authorisation from Wyak, or create a false identity on the
      Services;use the Services in a manner that may create a conflict of
      interest or undermine the purposes of the Services, such as trading
      reviews with other users or writing or soliciting fake reviews;<br />
      use the Services to upload, transmit, distribute, store or otherwise make
      available in any way:files that contain viruses, trojans, worms, logic
      bombs or other material that is malicious or technologically harmful;any
      unsolicited or unauthorised advertising, solicitations, promotional
      materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any
      other prohibited form of solicitation;any private information of any third
      party, including addresses, phone numbers, email addresses, number and
      feature in the personal identity document (e.g., National Insurance
      numbers, passport numbers) or credit card numbers;any material which does
      or may infringe any copyright, trade mark or other intellectual property
      or privacy rights of any other person;any material which is defamatory of
      any person, obscene, offensive, pornographic, hateful or inflammatory;any
      material that would constitute, encourage or provide instructions for a
      criminal offence, dangerous activities or self-harm;any material that is
      deliberately designed to provoke or antagonise people, especially trolling
      and bullying, or is intended to harass, harm, hurt, scare, distress,
      embarrass or upset people;any material that contains a threat of any kind,
      including threats of physical violence;any material that is racist or
      discriminatory, including discrimination on the basis of someone’s race,
      religion, age, gender, disability or sexuality; any answers, responses,
      comments, opinions, analysis or recommendations that you are not properly
      licensed or otherwise qualified to provide; or material that, in the sole
      judgment of Wyak, is objectionable or which restricts or inhibits any
      other person from using the Services, or which may expose Wyak, the
      Services or its users to any harm or liability of any type.<br />
      In addition to the above, your access to and use of the Services must, at
      all times, be compliant with our Community Guidelines.<br />
      We reserve the right, at any time and without prior notice, to remove or
      disable access to content at our discretion for any reason or no reason.
      Some of the reasons we may remove or disable access to content may include
      finding the content objectionable, in violation of these Terms or our
      Community Guidelines, or otherwise harmful to the Services or our users,
      or other contents that violate local laws. Wyak just provides the chat
      platform and cannot be expected to monitor all the content all the time
      but will endeavor to take down offensive content when notified. Our
      automated systems analyze your content (including emails) to provide you
      personally relevant product features, such as customized search results,
      tailored advertising, and spam and malware detection. This analysis occurs
      as the content is sent, received, and when it is stored.
    </p>
    <h2>6．Intellectual Property Rights</h2>
    <p>
      We respect intellectual property rights and ask you to do the same. As a
      condition of your access to and use of the Services, you agree not to use
      the Services to infringe on any intellectual property rights. We reserve
      the right, with or without notice, at any time and in our sole discretion
      to block access to and/or terminate the accounts of any user who infringes
      or is alleged to infringe any copyrights or other intellectual property
      rights.
    </p>
    <h2>7．Content</h2>
    <p>
      <b>Wyak Content</b><br />
      As between you and Wyak, all content, software, images, text, graphics,
      illustrations, logos, patents, trademarks, service marks, copyrights,
      photographs, audio, music on and “look and feel” of the Services, and all
      intellectual property rights related thereto (the “Wyak Content”), are
      either owned or licensed by Wyak, it being understood that you or your
      licensors will own any User Content you upload or transmit through the
      Services. Use of the Wyak Content or materials on the Services for any
      purpose not expressly permitted by these Terms is strictly prohibited.
      Such content may not be downloaded, copied, reproduced, distributed,
      transmitted, broadcast, displayed, sold, licensed or otherwise exploited
      for any purpose whatsoever without our or, where applicable, our
      licensors’ prior written consent. We and our licensors reserve all rights
      not expressly granted in and to their content.<br />
      You acknowledge and agree that we may generate revenues, increase goodwill
      or otherwise increase our value from your use of the Services, including,
      by way of example and not limitation, through the sale of Virtual Items
      and value-added services, and except as specifically permitted by us in
      these Terms or in another agreement you enter into with us, you will have
      no right to share in any such revenue, goodwill or value whatsoever. You
      further acknowledge that, except as specifically permitted by us in these
      Terms or in another agreement you enter into with us, you (i) have no
      right to receive any income or other consideration from any User Content
      or your use of any musical works, sound recordings or audiovisual clips
      made available to you on or through the Services, including in any User
      Content created by you, and (ii) are prohibited from exercising any rights
      to monetize or obtain consideration from any User Content within the
      Services or on any third party service (e.g. , you cannot claim User
      Content that has been uploaded to a social media platform such as YouTube
      for monetization).<br />
      Subject to the terms and conditions of the Terms, you are hereby granted a
      non-exclusive, limited, non-transferable, non-sublicensable, revocable,
      worldwide license to access and use the Services, including to download
      the Platform on a permitted device, and to access the Wyak Content solely
      for your personal, non-commercial use through your use of the Services and
      solely in compliance with these Terms. Wyak reserves all rights not
      expressly granted herein in the Services and the Wyak Content. You
      acknowledge and agree that Wyak may terminate this license at any time for
      any reason or no reason.<br />
      NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE MUSICAL
      WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE FROM OR THROUGH THE
      SERVICE.<br />
      You acknowledge and agree that when you view content provided by others on
      the Services, you are doing so at your own risk. The content on our
      Services is provided for general information only. It is not intended to
      amount to advice on which you should rely. You must obtain professional or
      specialist advice before taking, or refraining from, any action on the
      basis of the content on our Services.<br />
      We make no representations, warranties or guarantees, whether express or
      implied, that any Wyak Content (including User Content) is accurate,
      complete or up to date. Where our Services contain links to other sites
      and resources provided by third parties, these links are provided for your
      information only. We have no control over the contents of those sites or
      resources. Such links should not be interpreted as approval by us of those
      linked websites or information you may obtain from them. You acknowledge
      that we have no obligation to pre-screen, monitor, review, or edit any
      content posted by you and other users on the Services (including User
      Content).<br />
      B.User-Generated Content<br />
      Users of the Services may be permitted to upload, post or transmit (such
      as via a stream) or otherwise make available content through the Services
      including, without limitation, any text, photographs, user videos, sound
      recordings and the musical works embodied therein, including videos that
      incorporate locally stored sound recordings from your personal music
      library and ambient noise (“User Content”). Users of the Services may also
      extract all or any portion of User Content created by another user to
      produce additional User Content, including collaborative User Content with
      other users, that combine and intersperse User Content generated by more
      than one user. Users of the Services may also overlay music, graphics,
      stickers, Virtual Items and other elements provided by Wyak (“Wyak
      Elements”) onto this User Content and transmit this User Content through
      the Services. The information and materials in the User Content, including
      User Content that includes Wyak Elements, have not been verified or
      approved by us. The views expressed by other users on the Services
      (including through use of the virtual gifts) do not represent our views or
      values.<br />
      Whenever you access or use a feature that allows you to upload or transmit
      User Content through the Services (including via certain third party
      social media platforms such as Instagram, Facebook, YouTube, Twitter), or
      to make contact with other users of the Services, you must comply with the
      standards set out at “Your Access to and Use of Our Services” above. You
      may also choose to upload or transmit your User Content, including User
      Content that includes Wyak Elements, on sites or platforms hosted by third
      parties. If you decide to do this, you must comply with their content
      guidelines as well as with the standards set out at “Your Access to and
      Use of Our Services” above.<br />
      You warrant that any such contribution does comply with those standards,
      and you will be liable to us and indemnify us for any breach of that
      warranty. This means you will be responsible for any loss or damage we
      suffer as a result of your breach of warranty.<br />
      Any User Content will be considered non-confidential and non-proprietary.
      You must not post any User Content on or through the Services or transmit
      to us any User Content that you consider to be confidential or
      proprietary. When you submit User Content through the Services, you agree
      and represent that you own that User Content, or you have received all
      necessary permissions, clearances from, or are authorised by, the owner of
      any part of the content to submit it to the Services, to transmit it from
      the Services to other third party platforms, and/or adopt any third party
      content.<br />
      If you only own the rights in and to a sound recording, but not to the
      underlying musical works embodied in such sound recordings, then you must
      not post such sound recordings to the Services unless you have all
      permissions, clearances from, or are authorised by, the owner of any part
      of the content to submit it to the Services<br />
      You or the owner of your User Content still own the copyright in User
      Content sent to us, but by submitting User Content via the Services, you
      hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
      fully transferable, perpetual worldwide licence to use, modify, adapt,
      reproduce, make derivative works of, publish and/or transmit, and/or
      distribute and to authorise other users of the Services and other
      third-parties to view, access, use, download, modify, adapt, reproduce,
      make derivative works of, publish and/or transmit your User Content in any
      format and on any platform, either now known or hereinafter invented.<br />
      You further grant us a royalty-free license to use your user name, image,
      voice, and likeness to identify you as the source of any of your User
      Content.<br />
      For the avoidance of doubt, the rights granted in the preceding paragraphs
      of this Section include, but are not limited to, the right to reproduce
      sound recordings (and make mechanical reproductions of the musical works
      embodied in such sound recordings), and publicly perform and communicate
      to the public sound recordings (and the musical works embodied therein),
      all on a royalty-free basis. This means that you are granting us the right
      to use your User Content without the obligation to pay royalties to any
      third party, including, but not limited to, a sound recording copyright
      owner (e.g., a record label), a musical work copyright owner (e.g., a
      music publisher), a performing rights organization (e.g., ASCAP, BMI,
      SESAC, etc.) (a “PRO”), a sound recording PRO (e.g., SoundExchange), any
      unions or guilds, and engineers, producers or other royalty participants
      involved in the creation of User Content.<br />
      Specific Rules for Musical Works and for Recording Artists. If you are a
      composer or author of a musical work and are affiliated with a PRO, then
      you must notify your PRO of the royalty-free license you grant through
      these Terms in your User Content to us. You are solely responsible for
      ensuring your compliance with the relevant PRO’s reporting obligations. If
      you have assigned your rights to a music publisher, then you must obtain
      the consent of such music publisher to grant the royalty-free license(s)
      set forth in these Terms in your User Content or have such music publisher
      enter into these Terms with us. Just because you authored a musical work
      (e.g., wrote a song) does not mean you have the right to grant us the
      licenses in these Terms. If you are a recording artist under contract with
      a record label, then you are solely responsible for ensuring that your use
      of the Services is in compliance with any contractual obligations you may
      have to your record label, including if you create any new recordings
      through the Services that may be claimed by your label.<br />
      Through-To-The-Audience Rights. All of the rights you grant in your User
      Content in these Terms are provided on a through-to-the-audience basis,
      meaning the owners or operators of third party services will not have any
      separate liability to you or any other third party for User Content posted
      or used on such third party service via the Services.<br />
      Waiver of Rights to User Content. By posting User Content to or through
      the Services, you waive any rights to prior inspection or approval of any
      marketing or promotional materials related to such User Content. You also
      waive any and all rights of privacy, publicity, or any other rights of a
      similar nature in connection with your User Content, or any portion
      thereof, unless privacy setting is applied when you post such content. To
      the extent any moral rights are not transferable or assignable, you hereby
      waive and agree never to assert any and all moral rights, or to support,
      maintain or permit any action based on any moral rights that you may have
      in or with respect to any User Content you Post to or through the
      Services.<br />
      We also have the right to disclose your identity to any third party who is
      claiming that any User Content posted or uploaded by you to our Services
      constitutes a violation of their intellectual property rights, or of their
      right to privacy, and such disclosure will be pursuant to a legally
      binding court order.<br />
      We, or authorised third parties, reserve the right to cut, crop, edit or
      refuse to publish, your content at our or their sole discretion. We have
      the right to remove, disallow, block or delete any posting you make on our
      Services if, in our opinion, your post does not comply with the content
      standards set out at “Your Access to and Use of Our Services”above. In
      addition, we have the right – but not the obligation – in our sole
      discretion to remove, disallow, block or delete any User Content (i) that
      we consider to violate these Terms, or (ii) in response to complaints from
      other users or third parties, with or without notice and without any
      liability to you. As a result, we recommend that you save copies of any
      User Content that you post to the Services on your personal device(s) in
      the event that you want to ensure that you have permanent access to copies
      of such User Content. We do not guarantee the accuracy, integrity,
      appropriateness or quality of any User Content, and under no circumstances
      will we be liable in any way for any User Content.<br />
      You control whether your User Content is made publicly available on the
      Services to all other users of the Services or only available to people
      you approve. To restrict access to your User Content, you should select
      the privacy setting available within the Platform.<br />
      We accept no liability in respect of any content submitted by users and
      published by us or by authorised third parties.<br />
      If you wish to complain about information and materials uploaded by other
      users, please contact us in Wyak APP Feedback in Settings or email to
      wenext.wyak@gmail.com.live. Before we can respond to your request, you may
      be required to verify your identity or your account details.<br />
      Wyak takes reasonable measures to expeditiously remove from our Services
      any infringing material that we become aware of. It is Wyak’s policy, in
      appropriate circumstances and at its discretion, to disable or terminate
      the accounts of users of the Services who repeatedly infringe copyrights
      or intellectual property rights of others.<br />
      While our own staff is continually working to develop and evaluate our own
      product ideas and features, we pride ourselves on paying close attention
      to the interests, feedback, comments, and suggestions we receive from the
      user community. If you choose to contribute by sending us or our employees
      any ideas for products, services, features, modifications, enhancements,
      content, refinements, technologies, content offerings (such as audio,
      visual, games, or other types of content), promotions, strategies, or
      product/feature names, or any related documentation, artwork, computer
      code, diagrams, or other materials (collectively “Feedback”), then
      regardless of what your accompanying communication may say, the following
      terms will apply, so that future misunderstandings can be avoided.
      Accordingly, by sending Feedback to us , you agree that:<br />
      i. Wyak has no obligation to review, consider, or implement your Feedback,
      or to return to you all or part of any Feedback for any reason;<br />
      ii. Feedback is provided on a non-confidential basis, and we are not under
      any obligation to keep any Feedback you send confidential or to refrain
      from using or disclosing it in any way;<br />
      iii.And You irrevocably grant us perpetual and unlimited permission to
      reproduce, distribute, create derivative works of, modify, publicly
      perform (including on a through-to-the-audience basis), communicate to the
      public, make available, publicly display, and otherwise use and exploit
      the Feedback and derivatives thereof for any purpose and without
      restriction, free of charge and without attribution of any kind, including
      by making, using, selling, offering for sale, importing, and promoting
      commercial products and services that incorporate or embody Feedback,
      whether in whole or in part, and whether as provided or as modified.
    </p>
    <h2>8．Indemnity</h2>
    <p>
      You agree to defend, indemnify, and hold harmless Wyak, its parents,
      subsidiaries, and affiliates, and each of their respective officers,
      directors, employees, agents and advisors from any and all claims,
      liabilities, costs, and expenses, including, but not limited to,
      attorneys’ fees and expenses, arising out of a breach by you or any user
      of your account of these Terms or arising out of a breach of your
      obligations, representation and warranties under these Terms.
    </p>
    <h2>9．EXCLUSION OF WARRANTIES</h2>
    <p>
      NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CANNOT
      CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS ENTITLED TO
      AS A CONSUMER.THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR
      REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT
      REPRESENT OR WARRANT TO YOU THAT:<br />
      YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;<br />
      YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE
      FROM ERROR;ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
      SERVICES WILL BE ACCURATE OR RELIABLE;<br />
      AND EFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO
      YOU AS PART OF THE SERVICES WILL BE CORRECTED.<br />
      NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED TERMS AS
      TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE WITH
      DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT THEY ARE
      EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND, WITHDRAW OR
      RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR BUSINESS
      AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE
    </p>
    <h2>10．LIMITATION OF LIABILITY</h2>
    <p>
      NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR LOSSES
      WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. THIS
      INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE
      OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS AND FOR FRAUD
      OR FRAUDULENT MISREPRESENTATION.<br />
      SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:<br />
      (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY); (II) ANY
      LOSS OF GOODWILL; (III) ANY LOSS OF OPPORTUNITY; (IV) ANY LOSS OF DATA
      SUFFERED BY YOU; OR (V) ANY INDIRECT OR CONSEQUENTIAL LOSSES WHICH MAY BE
      INCURRED BY YOU. ANY OTHER LOSS WILL BE LIMITED TO THE AMOUNT PAID BY YOU
      TO Wyak WITHIN THE LAST 12 MONTHS.<br />
      ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF<br />
      ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR
      TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES
      WITHIN THE SERVICES);<br />
      THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER
      COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF
      THE SERVICES;<br />
      YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION;<br />
      OR YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND
      CONFIDENTIAL.<br />
      PLEASE NOTE THAT WE ONLY PROVIDE OUR PLATFORM FOR DOMESTIC AND PRIVATE
      USE. YOU AGREE NOT TO USE OUR PLATFORM FOR ANY COMMERCIAL OR BUSINESS
      PURPOSES, AND WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF
      BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION, BUSINESS INTERRUPTION,
      OR LOSS OF BUSINESS OPPORTUNITY.<br />
      IF DEFECTIVE DIGITAL CONTENT THAT WE HAVE SUPPLIED DAMAGES A DEVICE OR
      DIGITAL CONTENT BELONGING TO YOU AND THIS IS CAUSED BY OUR FAILURE TO USE
      REASONABLE CARE AND SKILL, WE WILL EITHER REPAIR THE DAMAGE OR PAY YOU
      COMPENSATION. HOWEVER, WE WILL NOT BE LIABLE FOR DAMAGE THAT YOU COULD
      HAVE AVOIDED BY FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU
      FREE OF CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY
      FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM
      REQUIREMENTS ADVISED BY US.<br />
      THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT WE
      HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY
      SUCH LOSSES ARISING.<br />
      YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF
      OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF YOU’RE UNSURE
      WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE
      USING THE SERVICE.<br />
      TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY
      THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING, BY WAY OF
      EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER OR OTHER USER, IS
      DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US
      AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL
      AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING
      OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
    </p>
    <h2>11．Other Terms</h2>
    <p>
      a. Applicable Law and Jurisdiction. Subject to the Supplemental Terms –
      Jurisdiction Specific, these Terms, their subject matter and their
      formation, are governed by the laws of Hong Kong. Any dispute arising out
      of or in connection with these Terms, including any question regarding
      existence, validity or termination of these Terms, shall be referred to
      and finally resolved by arbitration administered by the Hong Kong
      International Arbitration Centre in accordance with the Arbitration Rules
      of the Hong Kong International Arbitration Centre("DIAC Rules") for the
      time being in force, which rules are deemed to be incorporated by
      reference in this clause. The seat of the arbitration shall be Hong Kong.
      The Tribunal shall consist of three (3) arbitrators. The language of the
      arbitration shall be English.<br />
      b. Links. You may link to our home page, provided you do so in a way that
      is fair and legal and does not damage our reputation or take advantage of
      it. You must not establish a link in such a way as to suggest any form of
      association, approval or endorsement on our part where none exists. You
      must not establish a link to our Services in any website that is not owned
      by you. The website in which you are linking must comply in all respects
      with the content standards set out at “Your Access to and Use of Our
      Services” above. We reserve the right to withdraw linking permission
      without notice.<br />
      c. Age Limit.The Services are only for people 13 years old and over (with
      additional limits that may be set forth in the ). By using the Services,
      you confirm that you are over the relevant age specified herein. If we
      learn that someone under the relevant age specified above is using the
      Services, we will terminate that user’s account.<br />
      d. No Waiver. Our failure to insist upon or enforce any provision of these
      Terms shall not be construed as a waiver of any provision or right.<br />
      e. Security.We do not guarantee that our Services will be secure or free
      from bugs or viruses. You are responsible for configuring your information
      technology, computer programmes and platform to access our Services. You
      should use your own virus protection software.<br />
      f. Severability. If any court of law, having jurisdiction to decide on
      this matter, rules that any provision of these Terms is invalid, then that
      provision will be removed from the Terms without affecting the rest of the
      Terms, and the remaining provisions of the Terms will continue to be valid
      and enforceable.<br />
      g. Any Questions?Get in touch at wenext.wyak@gmail.com<br />
      Supplemental Terms – App Stores<br />
      To the extent permitted by applicable law, the following supplemental
      terms shall apply when accessing the Platform through specific devices:<br />
      Notice regarding Apple.<br />
      These Terms between Wyak and you; Apple is not a party to these Terms.<br />
      The license granted to you hereunder is limited to a personal, limited,
      non-exclusive, non-transferable right to install the Platform on the Apple
      device(s) authorised by Apple that you own or control for personal,
      non-commercial use, subject to the Usage Rules set forth in Apple’s App
      Store Terms of Services.<br />
      Apple is not responsible for the Platform or the content thereof and has
      no obligation whatsoever to furnish any maintenance or support services
      with respect to the Platform.<br />
      In the event of any failure of the Platform to conform to any applicable
      warranty, you may notify Apple, and Apple will refund the purchase price
      for the Platform, if any, to you. To the maximum extent permitted by
      applicable law, Apple will have no other warranty obligation whatsoever
      with respect to the Platform.<br />
      Apple is not responsible for addressing any claims by you or a third party
      relating to the Platform or your possession or use of the Platform,
      including without limitation (a) product liability claims; (b) any claim
      that the Platform fails to conform to any applicable legal or regulatory
      requirement; and (c) claims arising under consumer protection or similar
      legislation.<br />
      In the event of any third party claim that the Platform or your possession
      and use of the Platform infringes such third party’s intellectual property
      rights, Apple is not responsible for the investigation, defence,
      settlement or discharge of such intellectual property infringement
      claim.<br />
      You represent and warrant that (a) you are not located in a country that
      is subject to a U.S. Government embargo, or that has been designated by
      the U.S. Government as a “terrorist supporting” country; and (b) you are
      not listed on any U.S. Government list of prohibited or restricted
      parties.<br />
      Apple and its subsidiaries are third party beneficiaries of these Terms
      and upon your acceptance of the terms and conditions of these Terms, Apple
      will have the right (and will be deemed to have accepted the right) to
      enforce these Terms against you as a third party beneficiary hereof.<br />
      Wyak expressly authorises use of the Platform by multiple users through
      the Family Sharing or any similar functionality provided by Apple.<br />
      Google Play. By downloading the Platform from Google Play (or its
      successors) operated by Google, Inc. or one of its affiliates (“Google”),
      you specifically acknowledge and agree that:<br />
      to the extent of any conflict between (a) the Google Play Terms of
      Services and the Google Play Business and Program Policies or such other
      terms which Google designates as default end user license terms for Google
      Play (all of which together are referred to as the “Google Play Terms”),
      and (b) the other terms and conditions in these Terms, the Google Play
      Terms shall apply with respect to your use of the Platform that you
      download from Google Play,<br />
      and you hereby acknowledge that Google does not have any responsibility or
      liability related to compliance or non-compliance by Wyak or you (or any
      other user) under these Terms or the Google Play Terms.
    </p>
  </div>
</template>

<script>
export default {
  name: "ServiceTerms",
  metaInfo: {
    title: "Terms of Service",
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
  },
};
</script>

<style scoped>
h2 {
  text-align: start;
}
p {
  text-align: start;
}
</style>