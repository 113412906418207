import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import ServiceTerms from '@/components/ServiceTerms.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/privacy_policy', component: PrivacyPolicy },
  { path: '/service_terms', component: ServiceTerms },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
