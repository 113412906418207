import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // import the router

import './assets/styles/index.css' // 导入全局 CSS 文件
// import './assets/styles/reset.css' // 导入全局 CSS 文件


createApp(App)
  .use(router)  // use the router
  .mount('#app');
